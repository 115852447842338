import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  insertDuplicateHtml,
  insertSectionWithLine,
  removeLineSections,
  savePageState,
  setPageHtmlState,
  undo,
  updateUserHtml,
} from "../../Store/Slices/userHtmlSlice";
import { SourceDocument, UserWebsite } from "./sourceHtml.js";
import { LiaUndoAltSolid } from "react-icons/lia";

import phone from "../../assets/phone.png";
import Sidebar from "./Sidebar";
import "./slider.css";
import "./Mainpage.css";
import "./customsb.css";
import Publish from "./Publish.jsx";
import { createClient } from "@supabase/supabase-js";
import { RiFullscreenLine } from "react-icons/ri";
import { TbApps } from "react-icons/tb";
import { motion } from "framer-motion";
import AlertMessage from "./AlertMessage.jsx";
import { useParams } from "react-router-dom";
const supabase = createClient(
  "https://tdrdiflbdlftfwudjeim.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InRkcmRpZmxiZGxmdGZ3dWRqZWltIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDk4MzQ5MzEsImV4cCI6MjAyNTQxMDkzMX0.eJS9r_QHbfDFodV6Y5_AgB40vQ-Bbqx3oodyURjFKuY"
);

//GLobal Variables
var webPage = [];
var html = "";
var THE_ELEMENT = null;

function Mainpage() {
  const [wireMode, setWireMode] = useState(0);
  const [error, setError] = useState("");
  const { credId } = useParams();
  const iframeRef = useRef(null);
  const dispatch = useDispatch();
  const [mobileMode, setMobileMode] = useState(0);
  const [isSectionSeparatorOn, setIsSectionSeparatorOn] = useState(false);

  const [email, setEmail] = useState("");
  useEffect(() => {
    const getSession = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (error) {
        console.error("Error retrieving session:", error.message);
        return;
      }

      if (data) {
        if (
          data &&
          data.session &&
          data.session.user.id &&
          data.session.access_token
        ) {
          const email = data.session.user.email;
          setEmail(email);
        }
      }
    };

    getSession();
  }, []);
  useEffect(() => {
    dispatch(removeLineSections());
  }, []);
  function capitalizeFirstLetter(str) {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  function setPreview() {
    if (mobileMode === 0) {
      setMobileMode(1);
    } else {
      setMobileMode(0);
    }
  }
  const pages__ = useSelector((state) => state.userHtml?.pages);
  const functionalScript = useSelector((state) => state.userHtml?.script);
  const sectionSeparatorIds = useSelector(
    (state) => state.userHtml?.sectionSeparators
  );
  const domain = useSelector((state) => state.Name?.subdomain);
  const page_index = useSelector((state) => state.userHtml?.index) || "Home";
  const [pages_, setPages_] = useState(pages__);
  const [data__, setData__] = useState(null);
  useEffect(() => {
    if (pages__) {
      setPages_(pages__);
    } else {
      setError("Error Getting Data from Pages__");
    }
  }, [pages__]);
  useEffect(() => {
    if (pages_ && page_index && pages_["Home"]) {
      setData__(pages_[capitalizeFirstLetter(page_index)]);
    } else {
      setError("Error Getting Data from Pages_");
    }
  }, [pages_, page_index]);

  const [pageHtml, setPageHtml] = useState("");
  const history = useSelector((state) => state.userHtml?.history);
  const [id, setId] = useState([]);
  const addToIds = (newValue) => {
    setId((prevArray) => [...prevArray, newValue]);
  };
  const emptyArray = () => {
    setId([]);
  };

  function getOuterHtmlOfSectionElementById(id, iframeDocument) {
    const element = iframeDocument.getElementById(id);
    if (
      element &&
      (element.tagName.toLowerCase() === "section" ||
        element.tagName.toLowerCase() === "nav" ||
        element.tagName.toLowerCase() === "header" ||
        element.tagName.toLowerCase() === "div" ||
        element.tagName.toLowerCase() === "footer")
    ) {
      const outerHtml = element.outerHTML;
      return outerHtml;
    } else {
      return null;
    }
  }

  function replaceIdForTags(html) {
    const tagsToReplace = ["h1", "p", "a", "div", "button", "li", "ul", "img"];

    return html.replace(
      /(<(\w+)\s+[^>]*)(id="[^"]*")/gi,
      (match, openingTag, tagName, idAttribute) => {
        if (tagsToReplace.includes(tagName.toLowerCase())) {
          return openingTag + 'id="@id"';
        } else {
          return match;
        }
      }
    );
  }

  function hoverEventlistnerForElements(
    iframeDocument,
    element,
    overlay,
    overlay2,
    clickedInner
  ) {
    const userControls = iframeDocument.getElementById("fontEditor");
    if (userControls.style.display !== "flex") {
      const scrollTop =
        iframeDocument.documentElement.scrollTop ||
        iframeDocument.body.scrollTop;
      const scrollLeft =
        iframeDocument.documentElement.scrollLeft ||
        iframeDocument.body.scrollLeft;
      const rect = element.getBoundingClientRect();
      overlay2.style.display = "none";
      overlay.style.top = rect.top + scrollTop + "px";
      overlay.style.left = rect.left + scrollLeft + "px";
      overlay.style.width = rect.width + "px";
      overlay.style.height = rect.height + "px";
      overlay.style.display = "block";
      clickedInner = 1;
    }
  }
  function hoverEventlistnerForSections(
    iframeDocument,
    element,
    overlay,
    overlay2
  ) {
    const userControls = iframeDocument.getElementById("fontEditor");
    if (userControls.style.display !== "flex") {
      const scrollTop =
        iframeDocument.documentElement.scrollTop ||
        iframeDocument.body.scrollTop;
      const scrollLeft =
        iframeDocument.documentElement.scrollLeft ||
        iframeDocument.body.scrollLeft;
      const rect = element.getBoundingClientRect();
      overlay2.style.top = rect.top + scrollTop + "px";
      overlay2.style.left = rect.left + scrollLeft + "px";
      overlay2.style.width = rect.width + "px";
      overlay2.style.height = rect.height + "px";
      overlay2.style.display = "block";
      overlay.removeAttribute("style");
    }
  }
  function hoverEventlistnerForContainers(
    iframeDocument,
    element,
    overlay,
    overlay2,
    overlay3
  ) {
    const userControls = iframeDocument.getElementById("fontEditor");
    if (userControls.style.display !== "flex") {
      const scrollTop =
        iframeDocument.documentElement.scrollTop ||
        iframeDocument.body.scrollTop;
      const scrollLeft =
        iframeDocument.documentElement.scrollLeft ||
        iframeDocument.body.scrollLeft;
      const rect = element.getBoundingClientRect();
      overlay2.removeAttribute("style");
      overlay.removeAttribute("style");
      console.log("scroll", scrollTop);
      overlay3.style.top = rect.top + scrollTop + "px";
      overlay3.style.left = rect.left + scrollLeft + "px";
      overlay3.style.width = rect.width + "px";
      overlay3.style.height = rect.height + "px";
      overlay3.style.display = "block";
    }
  }

  function clickEventlistnerForElements(
    event,
    iframeDocument,
    element,
    overlay,
    overlay2,
    clearStyles,
    showEditor
  ) {
    overlay.removeAttribute("style");
    const scrollTop =
      iframeDocument.documentElement.scrollTop || iframeDocument.body.scrollTop;
    const scrollLeft =
      iframeDocument.documentElement.scrollLeft ||
      iframeDocument.body.scrollLeft;
    const rect = element.getBoundingClientRect();
    clearStyles();
    showEditor(element, event);
    overlay.style.top = rect.top + scrollTop + "px";
    overlay.style.left = rect.left + scrollLeft + "px";
    overlay.style.width = rect.width + "px";
    overlay.style.height = rect.height + "px";
    overlay.style.display = "block";
  }
  function clickEventlistnerForSections(
    event,
    iframeDocument,
    element,
    overlay,
    overlay2,
    clickedInner,
    showEditor
  ) {
    const scrollTop =
      iframeDocument.documentElement.scrollTop || iframeDocument.body.scrollTop;
    const scrollLeft =
      iframeDocument.documentElement.scrollLeft ||
      iframeDocument.body.scrollLeft;
    const rect = element.getBoundingClientRect();
    // const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
    const spinner = iframeDocument.getElementById("spinner");
    overlay2.style.top = rect.top + scrollTop + "px";
    overlay2.style.left = rect.left + scrollLeft + "px";
    overlay2.style.width = rect.width + "px";
    overlay2.style.height = rect.height + "px";
    overlay2.style.display = "block";
    overlay.removeAttribute("style");
    if (clickedInner === 0 && spinner.classList.contains("hidden")) {
      showEditor(element, event);
    }
  }
  function clickEventlistnerForContainers(
    event,
    iframeDocument,
    element,
    clearStyles,
    showEditor
  ) {
    clearStyles();
    const scrollTop =
      iframeDocument.documentElement.scrollTop || iframeDocument.body.scrollTop;
    const scrollLeft =
      iframeDocument.documentElement.scrollLeft ||
      iframeDocument.body.scrollLeft;
    const rect = element.getBoundingClientRect();
    const spinner = iframeDocument.getElementById("spinner");
    const overlay3 = iframeDocument.getElementById("overlay3");
    overlay3.style.top = rect.top + scrollTop + "px";
    overlay3.style.left = rect.left + scrollLeft + "px";
    overlay3.style.width = rect.width + "px";
    overlay3.style.height = rect.height + "px";
    overlay3.style.display = "block";

    showEditor(element, event);
    event.stopPropagation();
    if (spinner.classList.contains("hidden")) {
    }
  }

  function mouseLeaveEventlistnerForElements(clickedInner) {
    clickedInner = 0;
  }

  function addEventListener() {
    if (id?.length === data__?.length) {
      var clickedInner = 0;
      const iframe = iframeRef.current;

      const iframeDocument =
        iframe.contentDocument || iframe.contentWindow.document;
      const overlay = iframeDocument.getElementById("overlay");
      const overlay2 = iframeDocument.getElementById("overlay2");
      const overlay3 = iframeDocument.getElementById("overlay3");
      for (let i = 0; i < id?.length; i++) {
        const id_ = id[i];
        for (let i = 1; i <= 60; i++) {
          const elementId = `${id_}_${i}`;
          const element = iframeDocument.getElementById(elementId);
          if (element) {
            const tagName = element.tagName.toLowerCase();
            if (
              [
                "p",
                "h1",
                "h2",
                "h3",
                "h4",
                "h5",
                "img",
                "h6",
                "hr",
                "a",
                "iframe",
                "button",
                "span",
                "dt",
                "dd",
              ].includes(tagName) &&
              wireMode === 1
            ) {
              if (!element.classList.contains("cursor-pointer")) {
                element.classList.add("cursor-pointer");
              }
              element.addEventListener("click", (event) => {
                clickEventlistnerForElements(
                  event,
                  iframeDocument,
                  element,
                  overlay,
                  overlay2,
                  clearStyles,
                  showEditor
                );
              });
              element.addEventListener("mouseenter", () => {
                hoverEventlistnerForElements(
                  iframeDocument,
                  element,
                  overlay,
                  overlay2,
                  clickedInner
                );
              });
              element.addEventListener("mouseleave", () => {
                mouseLeaveEventlistnerForElements(clickedInner);
              });
            } else if (
              ["section", "nav", "div"].includes(tagName) &&
              wireMode === 3
            ) {
              if (!element.classList.contains("cursor-pointer")) {
                element.classList.add("cursor-pointer");
              }
              element.addEventListener("click", (event) => {
                clickEventlistnerForContainers(
                  event,
                  iframeDocument,
                  element,
                  clearStyles,
                  showEditor
                );
              });
              element.addEventListener("mouseenter", () => {
                hoverEventlistnerForContainers(
                  iframeDocument,
                  element,
                  overlay,
                  overlay2,
                  overlay3
                );
              });
              element.addEventListener("mouseleave", () => {});
            }
          }
        }
      }
    }
  }

  function removeAllEventListeners(elementId) {
    const iframe = iframeRef.current;
    const iframeWindow = iframe.contentWindow;
    const iframeDocument =
      iframe.contentDocument || iframe.contentWindow.document;
    const element = iframeDocument.getElementById(elementId);
    if (!element) {
      console.error(`Element with ID '${elementId}' not found.`);
      return;
    }

    const clonedElement = element.cloneNode(true);
    element.parentNode.replaceChild(clonedElement, element);
    const allLinks = iframeDocument.querySelectorAll("a");
    allLinks.forEach((link) => {
      link.addEventListener("click", function (event) {
        event.preventDefault();
      });
    });
  }

  function removeEventListener() {
    removeAllEventListeners(778899);
  }

  function getInnerText(element) {
    let text = "";

    if (element) {
      // Iterate over each child node of the element
      for (let node of element.childNodes) {
        // If it's a text node, append its content to the text variable
        if (node.nodeType === Node.TEXT_NODE) {
          text += node.textContent;
        }
        // If it's an element node, recursively call the function
        // to get its inner text
        else if (node.nodeType === Node.ELEMENT_NODE) {
          if (node.tagName === "BR") {
            text += "##break "; // Add /break for <br> tags
          } else {
            text += getInnerText(node);
          }
        }
      }
    }

    return text;
  }

  function showEditor(element, event) {
    const iframe = iframeRef.current;
    const iframeDocument =
      iframe.contentDocument || iframe.contentWindow.document;
    THE_ELEMENT = element;

    //*********  Fetching The Inner Components *********//
    //editor is the window's Id
    const editor_ = iframeDocument.getElementById("userControls");
    const controls_ = iframeDocument.getElementById("controls");
    const linkControls = iframeDocument.getElementById("linkControls");
    const textControls = iframeDocument.getElementById("textControls");
    const imageControls = iframeDocument.getElementById("imageControls");
    const buttonControls = iframeDocument.getElementById("buttonControls");

    const textEditorArea = iframeDocument.getElementById("textEditorArea");
    if (textEditorArea) {
      const innerText = getInnerText(THE_ELEMENT);
      textEditorArea.innerText = innerText;
    }

    iframeDocument.getElementById("textInput").value = "";
    iframeDocument.getElementById(
      "textInput"
    ).placeholder = `What do you want to edit?`;

    editor_.removeAttribute("style");
    editor_.style.display = "flex";
    controls_.removeAttribute("style");
    controls_.style.display = "flex";

    const editorWidth = editor_.offsetWidth;
    const editorHeight = editor_.offsetHeight;
    const iframeContentWidth = iframeDocument.documentElement.clientWidth;
    const iframeTotalHeight = iframeDocument.documentElement.offsetHeight;
    const pointerXWithinIframe = event.clientX;
    const pointeryWithinIframe = event.clientY;
    const remainingSpaceWithinIframe =
      iframeContentWidth - pointerXWithinIframe;

    // Calculating the Left Offset
    if (remainingSpaceWithinIframe < 390) {
      const leftPositionWithinIframe = Math.max(
        0,
        pointerXWithinIframe - editorWidth
      );
      editor_.style.left = `${leftPositionWithinIframe}px`;
    } else {
      editor_.style.left = `${pointerXWithinIframe}px`;
    }

    if (iframeTotalHeight - event.pageY < 250) {
      editor_.style.top = `${event.pageY - editorHeight}px`;
    } else {
      editor_.style.top = `${event.pageY}px`;
    }
    editor_.style.zIndex = "9999";

    const tagName = THE_ELEMENT.tagName.toLowerCase();

    if (
      [
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "p",
        "b",
        "emp",
        "i",
        "dl",
        "dd",
      ].includes(tagName)
    ) {
      textControls.style.display = "flex";
      linkControls.removeAttribute("style");
      buttonControls.removeAttribute("style");
      imageControls.removeAttribute("style");
    } else if (["a"].includes(tagName)) {
      linkControls.style.display = "flex";
      textControls.removeAttribute("style");
      buttonControls.removeAttribute("style");
      imageControls.removeAttribute("style");
    } else if (["button"].includes(tagName)) {
      buttonControls.style.display = "flex";
      textControls.removeAttribute("style");
      linkControls.removeAttribute("style");
      imageControls.removeAttribute("style");
    } else if (["img"].includes(tagName)) {
      imageControls.style.display = "flex";
      textControls.removeAttribute("style");
      linkControls.removeAttribute("style");
      buttonControls.removeAttribute("style");
    } else {
      linkControls.removeAttribute("style");
      buttonControls.removeAttribute("style");
      imageControls.removeAttribute("style");
      textControls.removeAttribute("style");
    }
  }

  useEffect(() => {
    removeEventListener();
    clearStyles();
    addEventListener();
  }, [wireMode]);

  const backgroundImageEditor = () => {
    const iframe = iframeRef.current;
    if (iframe) {
      const iframeDocument =
        iframe.contentDocument || iframe.contentWindow.document;

      if (iframeDocument) {
        const buttons = iframeDocument.querySelectorAll(
          'button[name="background-image-editor-button"]'
        );

        // Remove all buttons except the one with id="original"
        buttons.forEach((button) => {
          if (button.id !== "original") {
            button.remove();
          }
        });

        const buttons2 = iframeDocument.querySelectorAll(
          'button[name="background-image-editor-close-button"]'
        );

        // Remove all buttons except the one with id="original"
        buttons2.forEach((button) => {
          if (button.id !== "original") {
            button.remove();
          }
        });

        const originalButton = iframeDocument.querySelector(
          'button[name="background-image-editor-button"]'
        );

        // Find the image
        const image = iframeDocument.querySelector(
          'img[name="background-image"]'
        );

        if (originalButton && image) {
          const section = image.closest("section");

          if (section) {
            // Clone the original button
            const clonedButton = originalButton.cloneNode(true);

            // Generate a random ID
            const generateRandomId = () => {
              const chars =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
              return Array.from(
                { length: 12 },
                () => chars[Math.floor(Math.random() * chars.length)]
              ).join("");
            };
            clonedButton.id = generateRandomId();

            // Style the cloned button
            clonedButton.style.position = "absolute";
            clonedButton.style.top = "1rem"; // top-4 equivalent
            clonedButton.style.right = "1rem"; // right-4 equivalent
            clonedButton.style.zIndex = "100";
            clonedButton.style.display = "flex";

            // Create a new "cross" button
            const crossButton = document.createElement("button");
            crossButton.name = "background-image-editor-close-button";
            crossButton.className =
              "bg-black text-white rounded-lg px-3 gap-2 w-auto py-2 hidden items-center justify-center";
            crossButton.style.position = "absolute";
            crossButton.style.display = "flex";
            crossButton.style.top = "1rem";
            crossButton.style.right = "1rem";
            crossButton.style.zIndex = "100";

            // Add cross icon to the button
            crossButton.innerHTML = `
         <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-arrow-down-from-line"><path d="M19 3H5"/><path d="M12 21V7"/><path d="m6 15 6 6 6-6"/></svg>
          <p>
          Send to Back
          </p>
        `;

            // Add click event to bring the background image forward and replace button with cross
            clonedButton.addEventListener("click", () => {
              image.style.zIndex = "100"; // Bring the image forward
              clonedButton.remove(); // Remove the cloned button
              section.appendChild(crossButton); // Add the cross button
            });

            // Add click event to reset z-index and replace cross with the original button
            crossButton.addEventListener("click", () => {
              image.removeAttribute("style"); // Reset the z-index
              crossButton.remove(); // Remove the cross button
              section.appendChild(clonedButton); // Add the cloned button back
            });

            // Append the cloned button to the section
            section.style.position = "relative"; // Ensure section is positioned
            section.appendChild(clonedButton);
          }
        }
      }
    }
  };
  useEffect(() => {
    if (id?.length === data__?.length) {
      const iframe = iframeRef.current;
      iframe.onload = () => {
        const iframeWindow = iframe.contentWindow;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;
        const spinner = iframeDocument.getElementById("spinner");
        iframeWindow.addEventListener("scroll", () => {
          if (spinner.classList.contains("hidden")) {
            clearStyles();
          }
        });
        backgroundImageEditor();
        addEventListener();

        // Function to handle the drop event on image elements
        const handleImageDrop = (event) => {
          event.preventDefault();
          const droppedSrc = event.dataTransfer.getData("text/plain"); // Get the dropped image source
          event.target.src = droppedSrc; // Change the src of the dropped-on image
          const editedImage = event.target; // Reference to the edited image element
          let currentElement = editedImage.parentElement;
          const targetTagNames = [
            "SECTION",
            "NAV",
            "MAIN",
            "HEADER",
            "FOOTER",
            "DIV",
          ];
          let parentId = null;
          while (currentElement) {
            if (targetTagNames.includes(currentElement.tagName)) {
              parentId = currentElement.id;
              break;
            }
            currentElement = currentElement.parentElement;
          }

          if (parentId) {
            const element_id = parentId;
            const parts = element_id.split("_");
            if (parts?.length > 1) {
              parts.pop();
              const newNumber = 1;
              const sectionIdforUpdate = parts.join("_") + "_" + newNumber;
              console.log(
                "Respective Section Id of which element is edited",
                sectionIdforUpdate
              ); // This will log "652ea9a013e4317a53af6b6e_1"
              const sectionHtml = getOuterHtmlOfSectionElementById(
                sectionIdforUpdate,
                iframeDocument
              );
              if (sectionHtml) {
                const modifiedHtml = sectionHtml.replace(
                  /id="[^"]*"/g,
                  'id="@id"'
                );
                const payload = {
                  _id: parts[0],
                  newHtmlTemplate: modifiedHtml,
                };
                dispatch(updateUserHtml(payload));
              } else {
                console.log(
                  "Section element not found or tagName is not 'section'."
                );
              }
            } else {
              console.log(id);
            }
          }
        };

        // Get all image elements and attach the drop event listener to each one
        const imageTags = iframeDocument.querySelectorAll("img"); // Get all image tags
        imageTags.forEach((img) => {
          img.addEventListener("drop", handleImageDrop); // Attach drop event listener
          img.addEventListener("dragover", (event) => event.preventDefault()); // Prevent default behavior
        });

        // ******* ShowEditor Function : This function shows the editor. *******//
        function showEditor(element, event) {
          THE_ELEMENT = element;
          const overlay = iframeDocument.getElementById("overlay");
          const scrollTop =
            iframeDocument.documentElement.scrollTop ||
            iframeDocument.body.scrollTop;
          const scrollLeft =
            iframeDocument.documentElement.scrollLeft ||
            iframeDocument.body.scrollLeft;
          const rect = element.getBoundingClientRect();

          //*********  Fetching The Inner Components *********//
          //editor is the window's Id
          const editor_ = iframeDocument.getElementById("userControls");
          const controls_ = iframeDocument.getElementById("controls");
          const linkControls = iframeDocument.getElementById("linkControls");
          const textControls = iframeDocument.getElementById("textControls");
          const imageControls = iframeDocument.getElementById("imageControls");
          const buttonControls =
            iframeDocument.getElementById("buttonControls");

          // const PromptEditor_ =  iframeDocument.getElementById('PromptEditor')
          // const UserEditor_ =  iframeDocument.getElementById('UserEditor')

          //Prompt Editor TextInput & PlaceHoler
          iframeDocument.getElementById("textInput").value = "";
          iframeDocument.getElementById(
            "textInput"
          ).placeholder = `What do you want to edit?`;

          //Displaying the little window

          editor_.removeAttribute("style");
          editor_.style.display = "flex";
          controls_.removeAttribute("style");
          controls_.style.display = "flex";

          // Calculating the Position for displaying the editor window
          const editorWidth = editor_.offsetWidth;
          const editorHeight = editor_.offsetHeight;
          const iframeContentWidth = iframeDocument.documentElement.clientWidth;
          const iframeTotalHeight = iframeDocument.documentElement.offsetHeight;
          const pointerXWithinIframe = event.clientX;
          const pointeryWithinIframe = event.clientY;
          const remainingSpaceWithinIframe =
            iframeContentWidth - pointerXWithinIframe;

          // Calculating the Left Offset
          if (remainingSpaceWithinIframe < 250) {
            const leftPositionWithinIframe = Math.max(
              0,
              pointerXWithinIframe - editorWidth
            );
            editor_.style.left = `${leftPositionWithinIframe}px`;
          } else {
            editor_.style.left = `${pointerXWithinIframe}px`;
          }

          // Calculating the Top Offset
          if (iframeTotalHeight - event.pageY < 250) {
            editor_.style.top = `${event.pageY - editorHeight}px`;
          } else {
            editor_.style.top = `${event.pageY}px`;
          }
          editor_.style.zIndex = "10";

          const tagName = THE_ELEMENT.tagName.toLowerCase();

          //Displaying the Editing Component for heading, paragraphs in textual data for direct editing
          if (
            [
              "h1",
              "h2",
              "h3",
              "h4",
              "h5",
              "h6",

              "p",
              "b",
              "emp",
              "i",
              "span",
              "dt",
              "dd",
              "iframe",
            ].includes(tagName)
          ) {
            textControls.style.display = "flex";
            linkControls.removeAttribute("style");
            buttonControls.removeAttribute("style");
            imageControls.removeAttribute("style");
          } else if (["a"].includes(tagName)) {
            linkControls.style.display = "flex";
            textControls.removeAttribute("style");
            buttonControls.removeAttribute("style");
            imageControls.removeAttribute("style");
          } else if (["button"].includes(tagName)) {
            buttonControls.style.display = "flex";
            textControls.removeAttribute("style");
            linkControls.removeAttribute("style");
            imageControls.removeAttribute("style");
          } else if (["img"].includes(tagName)) {
            imageControls.style.display = "flex";
            textControls.removeAttribute("style");
            linkControls.removeAttribute("style");
            buttonControls.removeAttribute("style");
          } else {
            linkControls.removeAttribute("style");
            buttonControls.removeAttribute("style");
            imageControls.removeAttribute("style");
            textControls.removeAttribute("style");
          }
        }

        const editTextButton_tc =
          iframeDocument.getElementById("editTextButton-tc");
        const editLinkButton_lc =
          iframeDocument.getElementById("editLinkButton-lc");
        const editFontButton_tc =
          iframeDocument.getElementById("editFontButton-tc");
        const editFontButton_lc =
          iframeDocument.getElementById("editFontButton-lc");
        const editTextButton_lc =
          iframeDocument.getElementById("editTextButton-lc");
        const editText_bc = iframeDocument.getElementById("editText-bc");
        const openImage_ic = iframeDocument.getElementById("openImage-ic");
        const fontDropdown = iframeDocument.getElementById("fontDropdown");
        const updateFont = iframeDocument.getElementById("updateFont");
        const sv = iframeDocument.getElementById("saveButton");
        const loader = iframeDocument.getElementById("spinner");

        const fontNames = [
          "font-roboto",
          "font-open-sans",
          "font-montserrat",
          "font-lato",
          "font-source-sans-pro",
          "font-roboto-condensed",
          "font-poppins",
          "font-raleway",
          "font-nunito",
          "font-merriweather",
          "font-ubuntu",
          "font-playfair-display",
          "font-inconsolata",
          "font-josefin-sans",
          "font-pt-sans",
          "font-fira-sans",
          "font-dosis",
          "font-rubik",
          "font-oswald",
          "font-bebas-neue",
          "font-quicksand",
          "font-manrope",
          "font-barlow",
          "font-exo",
          "font-arimo",
          "font-heebo",
          "font-mulish",
          "font-asap",
          "font-inter",
          "font-signika",
          "font-overpass",
          "font-fjalla-one",
          "font-space-mono",
          "font-karla",
          "font-varela-round",
          "font-cabin",
          "font-titillium-web",
          "font-arvo",
          "font-alegreya",
          "font-hind",
          "font-catamaran",
          "font-yanone-kaffeesatz",
          "font-crimson-text",
          "font-work-sans",
          "font-questrial",
          "font-zilla-slab",
          "font-kanit",
          "font-cardo",
          "font-abel",
          "font-pt-serif",
        ];

        if (fontDropdown) {
          fontDropdown.addEventListener("change", () => {
            const selectedFont = fontDropdown.value;
            fontNames.forEach((fontName) => {
              if (THE_ELEMENT.classList.contains(fontName)) {
                THE_ELEMENT.classList.remove(fontName);
              }
            });
            THE_ELEMENT.classList.add(selectedFont);
          });
        }

        if (updateFont) {
          updateFont.addEventListener("click", (event) => {
            if (THE_ELEMENT) {
              const element_id = THE_ELEMENT.id;
              const parts = element_id.split("_");
              if (parts?.length > 1) {
                parts.pop();
                const newNumber = 1;
                const sectionIdforUpdate = parts.join("_") + "_" + newNumber;
                console.log(
                  "Respective Section Id of which element is edited",
                  sectionIdforUpdate
                ); // This will log "652ea9a013e4317a53af6b6e_1"
                const sectionHtml = getOuterHtmlOfSectionElementById(
                  sectionIdforUpdate,
                  iframeDocument
                );
                if (sectionHtml) {
                  const modifiedHtml = replaceIdForTags(sectionHtml);
                  const payload = {
                    _id: parts[0],
                    newHtmlTemplate: modifiedHtml,
                  };

                  clearStyles();

                  dispatch(updateUserHtml(payload));
                } else {
                  console.log(
                    "Section element not found or tagName is not 'section'."
                  );
                }
              } else {
                console.log(id);
              }
            }
          });
        }

        openImage_ic &&
          openImage_ic.addEventListener("click", () => {
            const imageEditor = iframeDocument.getElementById("imageEditor");
            const userControls = iframeDocument.getElementById("controls");
            const PromptEditor = iframeDocument.getElementById("PromptEditor");

            // Change style of textEditor to flex and userControls to hidden
            if (imageEditor) {
              imageEditor.style.display = "flex";
            }
            if (userControls) {
              userControls.style.display = "none";
            }
            if (PromptEditor) {
              PromptEditor.style.display = "none";
            }
          });

        iframeDocument.getElementById("uploadInput") &&
          iframeDocument
            .getElementById("uploadInput")
            .addEventListener("change", async (event) => {
              const fileNameElement =
                iframeDocument.getElementById("imageFileName");

              fileNameElement.textContent = "Uploading... ";
              const file = event.target.files[0];
              if (!file) return;

              // Generate a random string of 6 digits
              function generateRandomString() {
                return Math.random().toString(36).substring(2, 8);
              }

              // Upload the image

              const randomDigits = generateRandomString();
              const newFileName = `ztr07r_${randomDigits}_${file.name}`;

              console.log("name === > ", newFileName);

              try {
                const { data, error } = await supabase.storage
                  .from("hello-world")
                  .upload(newFileName, file, {
                    cacheControl: "3600",
                    upsert: true, // Allow overwriting if the file already exists
                    contentType: file.type,
                  });

                if (data) {
                  console.log("Data = = = = >", data);
                  // Retrieve the image URL
                  const imageUrl = `https://tdrdiflbdlftfwudjeim.supabase.co/storage/v1/object/public/${data.fullPath}`;

                  // Update image source
                  const displayImage =
                    iframeDocument.getElementById("displayImage");
                  displayImage.innerHTML = "";
                  const img = new Image();
                  img.src = imageUrl;
                  displayImage.appendChild(img);

                  // Update file name in <p> element
                  fileNameElement.textContent = file.name;

                  // Update src attribute of THE_ELEMENT with the uploaded image URL
                  THE_ELEMENT.src = imageUrl;
                } else {
                  console.log(error);
                }

                if (error) {
                  throw new Error(`Error uploading image: ${error.message}`);
                }
              } catch (error) {
                fileNameElement.textContent = error.message;
                console.error(error.message);
              }
            });

        function openTextEditor() {
          const textEditor = iframeDocument.getElementById("textEditor");
          const userControls = iframeDocument.getElementById("controls");
          const PromptEditor = iframeDocument.getElementById("PromptEditor");

          if (textEditor) {
            textEditor.style.display = "flex";
          }
          if (userControls) {
            userControls.style.display = "none";
          }
          if (PromptEditor) {
            PromptEditor.style.display = "none";
          }
        }

        editTextButton_tc &&
          editTextButton_tc.addEventListener("click", openTextEditor);
        editText_bc && editText_bc.addEventListener("click", openTextEditor);
        editTextButton_lc &&
          editTextButton_lc.addEventListener("click", openTextEditor);

        editLinkButton_lc &&
          editLinkButton_lc.addEventListener("click", () => {
            const linkEditor = iframeDocument.getElementById("linkEditor");
            const userControls = iframeDocument.getElementById("controls");
            const PromptEditor = iframeDocument.getElementById("PromptEditor");
            const linkEditorInput =
              iframeDocument.getElementById("linkEditorInput");
            const url = new URL(THE_ELEMENT.href);

            console.log("URL", url);
            const path = url.pathname;
            console.log("MODI", path);
            linkEditorInput.value = path;

            // Change style of textEditor to flex and userControls to hidden
            if (linkEditor) {
              linkEditor.style.display = "flex";
            }
            if (userControls) {
              userControls.style.display = "none";
            }
            if (PromptEditor) {
              PromptEditor.style.display = "none";
            }
          });

        function openFontEditor() {
          const fontEditor = iframeDocument.getElementById("fontEditor");
          const userControls = iframeDocument.getElementById("controls");
          const PromptEditor = iframeDocument.getElementById("PromptEditor");

          // Change style of textEditor to flex and userControls to hidden
          if (fontEditor) {
            fontEditor.style.display = "flex";
          }
          if (userControls) {
            userControls.style.display = "none";
          }
          if (PromptEditor) {
            PromptEditor.style.display = "none";
          }
        }

        editFontButton_tc &&
          editFontButton_tc.addEventListener("click", openFontEditor);
        editFontButton_lc &&
          editFontButton_lc.addEventListener("click", openFontEditor);

        sv &&
          sv.addEventListener("click", () => {
            if (THE_ELEMENT) {
              console.log("Selected Element : ", THE_ELEMENT);
              THE_ELEMENT.classList.remove();
              console.log("Selected Html : ", html);

              if (loader) {
                loader.classList.remove("hidden");
              }
              const postData = {
                prompt: iframeDocument.getElementById("textInput").value,
                html: THE_ELEMENT.outerHTML,
              };

              GPTApiCall(postData, THE_ELEMENT);
            }
          });

        const updateLink = iframeDocument.getElementById("updateLink");
        const updateImage = iframeDocument.getElementById("updateImage");
        const linkEditorInput =
          iframeDocument.getElementById("linkEditorInput");

        linkEditorInput &&
          linkEditorInput.addEventListener("input", () => {
            THE_ELEMENT.href = linkEditorInput.value;
          });

        if (updateLink) {
          updateLink.addEventListener("click", (event) => {
            if (THE_ELEMENT) {
              console.log("MODI = >", THE_ELEMENT);

              // if (THE_ELEMENT.href.includes("http")) {
              //   const pos = THE_ELEMENT.href.indexOf("http");
              //   // Slice the string so that it starts from "http"
              //   THE_ELEMENT.href = THE_ELEMENT.href.slice(pos);
              // }

              // console.log("MODI = >", THE_ELEMENT);
              const element_id = THE_ELEMENT.id;
              const parts = element_id.split("_");
              if (parts?.length > 1) {
                parts.pop();
                const newNumber = 1;
                const sectionIdforUpdate = parts.join("_") + "_" + newNumber;
                console.log(
                  "Respective Section Id of which element is edited",
                  sectionIdforUpdate
                ); // This will log "652ea9a013e4317a53af6b6e_1"
                const sectionHtml = getOuterHtmlOfSectionElementById(
                  sectionIdforUpdate,
                  iframeDocument
                );
                if (sectionHtml) {
                  const modifiedHtml = sectionHtml.replace(
                    /id="[^"]*"/g,
                    'id="@id"'
                  );
                  const payload = {
                    _id: parts[0],
                    newHtmlTemplate: modifiedHtml,
                  };

                  linkEditorInput.value = "";
                  clearStyles();

                  dispatch(updateUserHtml(payload));
                } else {
                  console.log(
                    "Section element not found or tagName is not 'section'."
                  );
                }
              } else {
                console.log(id);
              }
            }
          });
        }

        if (updateImage) {
          updateImage.addEventListener("click", (event) => {
            if (THE_ELEMENT) {
              const element_id = THE_ELEMENT.id;
              const parts = element_id.split("_");
              if (parts?.length > 1) {
                parts.pop();
                const newNumber = 1;
                const sectionIdforUpdate = parts.join("_") + "_" + newNumber;
                console.log(
                  "Respective Section Id of which element is edited",
                  sectionIdforUpdate
                ); // This will log "652ea9a013e4317a53af6b6e_1"
                const sectionHtml = getOuterHtmlOfSectionElementById(
                  sectionIdforUpdate,
                  iframeDocument
                );
                if (sectionHtml) {
                  const modifiedHtml = sectionHtml.replace(
                    /id="[^"]*"/g,
                    'id="@id"'
                  );
                  const payload = {
                    _id: parts[0],
                    newHtmlTemplate: modifiedHtml,
                  };

                  linkEditorInput.value = "";
                  clearStyles();
                  const displayImage =
                    iframeDocument.getElementById("displayImage");

                  // Remove all existing child elements
                  while (displayImage.firstChild) {
                    displayImage.removeChild(displayImage.firstChild);
                  }
                  dispatch(updateUserHtml(payload));
                } else {
                  console.log(
                    "Section element not found or tagName is not 'section'."
                  );
                }
              } else {
                console.log(id);
              }
            }
          });
        }

        // const updateFont = iframeDocument.getElementById('updateFont')
        // const fontEditor = iframeDocument.getElementById('fontEditor')

        // Function to get inner text of an element

        const UpdateText = iframeDocument.getElementById("UpdateText");
        const textEditorArea = iframeDocument.getElementById("textEditorArea");
        textEditorArea &&
          textEditorArea.addEventListener("input", () => {
            const editorValue = textEditorArea.value;
            const replacedValue = editorValue.replace(/##break/g, "<br>");
            THE_ELEMENT.innerHTML = replacedValue;
          });

        UpdateText &&
          UpdateText.addEventListener("click", (event) => {
            if (THE_ELEMENT) {
              const element_id = THE_ELEMENT.id;
              console.log("THE ELEMENT IN ", element_id);
              const parts = element_id.split("_");
              if (parts?.length > 1) {
                parts.pop();
                const newNumber = 1;
                const sectionIdforUpdate = parts.join("_") + "_" + newNumber;
                console.log(
                  "Respective Section Id of which element is edited",
                  sectionIdforUpdate
                ); // This will log "652ea9a013e4317a53af6b6e_1"
                const sectionHtml = getOuterHtmlOfSectionElementById(
                  sectionIdforUpdate,
                  iframeDocument
                );

                console.log("the section html : - ", sectionHtml);
                if (sectionHtml) {
                  const modifiedHtml = replaceIdForTags(sectionHtml);
                  const payload = {
                    _id: parts[0],
                    newHtmlTemplate: modifiedHtml,
                  };

                  textEditorArea.value = "";
                  clearStyles();
                  dispatch(updateUserHtml(payload));
                  // dispatch(addToHistory());
                } else {
                  console.log(
                    "Section element not found or tagName is not 'section'."
                  );
                }
              } else {
                console.log(id);
              }
            }
          });
      };
    }
  }, [id]);

  function clearStyles() {
    const iframe = iframeRef.current;
    const iframeDocument =
      iframe.contentDocument || iframe.contentWindow.document;
    const textEditor = iframeDocument.getElementById("textEditor");
    const fontEditor = iframeDocument.getElementById("fontEditor");
    const userControls = iframeDocument.getElementById("userControls");
    const PromptEditor = iframeDocument.getElementById("PromptEditor");
    const imageEditor = iframeDocument.getElementById("imageEditor");
    const linkEditor = iframeDocument.getElementById("linkEditor");
    const ov = iframeDocument.getElementById("overlay");
    const ov2 = iframeDocument.getElementById("overlay2");
    const ov3 = iframeDocument.getElementById("overlay3");
    if (ov) {
      ov.removeAttribute("style");
    }
    if (ov3) {
      ov3.removeAttribute("style");
    }
    if (ov2) {
      ov2.removeAttribute("style");
    }
    //Remove styles from textEditor
    if (textEditor) {
      textEditor.removeAttribute("style");
    }
    if (fontEditor) {
      fontEditor.removeAttribute("style");
    }

    if (imageEditor) {
      imageEditor.removeAttribute("style");
    }

    //Remove styles from textEditor
    if (linkEditor) {
      linkEditor.removeAttribute("style");
    }

    // Remove styles from userControls
    if (userControls) {
      userControls.removeAttribute("style");
      userControls.style.display = "none";
    }

    // Remove styles from PromptEditor
    if (PromptEditor) {
      PromptEditor.removeAttribute("style");
    }
  }

  async function GPTApiCall(postData_, THE_ELEMENT) {
    const iframe = iframeRef.current;
    const iframeDocument =
      iframe.contentDocument || iframe.contentWindow.document;
    const element_id = THE_ELEMENT.id;
    console.log("ID  :", element_id);
    const apiEndpoint =
      process.env.REACT_APP_API_ENDPOINT || "http://localhost:8080";
    const apiUrl = `${apiEndpoint}/helloworld/generative/modify`;
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData_), // Convert postData to JSON string
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.msg === "success") {
          const loader = iframeDocument.getElementById("spinner");
          if (loader) {
            loader.classList.add("hidden");
          }

          //Replacing Previous Elements
          const newElement = iframeDocument.createElement("div");
          const newHtml = data.html;
          newElement.innerHTML = newHtml;
          const replacementElement = newElement.firstChild;
          if (replacementElement) {
            THE_ELEMENT.parentNode.replaceChild(
              replacementElement,
              THE_ELEMENT
            );
            iframeDocument.getElementById("userControls").style.display =
              "none";
          }

          //Page Re Rendering
          const parts = element_id.split("_");
          if (parts?.length > 1) {
            parts.pop();
            const newNumber = 1;
            const sectionIdforUpdate = parts.join("_") + "_" + newNumber;
            console.log(
              "Respective Section Id of which element is edited",
              sectionIdforUpdate
            ); // This will log "652ea9a013e4317a53af6b6e_1"
            const sectionHtml = getOuterHtmlOfSectionElementById(
              sectionIdforUpdate,
              iframeDocument
            );
            if (sectionHtml) {
              const modifiedHtml = replaceIdForTags(sectionHtml);
              const payload = {
                _id: parts[0],
                newHtmlTemplate: modifiedHtml,
              };
              dispatch(updateUserHtml(payload));
              // dispatch(addToHistory());
            } else {
              console.log(
                "Section element not found or tagName is not 'section'."
              );
            }
          } else {
            console.log(id);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching API:", error);
      });
  }

  // The combined html of the page.

  useEffect(() => {
    html = "";
    webPage = [];
    emptyArray();
    if (data__ && data__?.length > 0) {
      for (const element of data__) {
        manipulate_section_string(element._id, element.htmlTemplate);

        addToIds(element._id);
      }
      html = webPage.join("");

      setPageHtml(html);
      dispatch(setPageHtmlState(html));
    }
  }, [data__]);

  const containerRefy = useRef(null);
  useEffect(() => {
    if (containerRefy.current) {
      const container = containerRefy.current;
      container.innerHTML = pageHtml;
    }
  }, [pageHtml]);

  // useEffect(() => {
  //   const iframe = iframeRef.current;
  //   iframe.onload = () => {
  //     const iframeDocument =
  //       iframe.contentDocument || iframe.contentWindow.document;

  //     const drop = iframeDocument.getElementsByName("dropdown-container")[0];
  //     console.log("drop", drop);
  //     drop.style.display = "flex";
  //   };
  // }, [mobileMode]);
  //For Slider
  const containerStyles = {
    transition: "all 0.2s",
    width: mobileMode ? "500px" : "100%",
    maxWidth: "100%",
  };

  function manipulate_section_string(_id, htmlTemplate) {
    var htmlStr = htmlTemplate;
    htmlStr = addIdAttribute(htmlStr, "@id");
    htmlStr = replaceIds(htmlStr, _id);
    if (htmlStr.startsWith('"') && htmlStr.endsWith('"')) {
      htmlStr = htmlStr.slice(1, -1);
    }
    if (htmlStr.startsWith("'") && htmlStr.endsWith("'")) {
      htmlStr = htmlStr.slice(1, -1);
    }
    webPage.push(htmlStr);
  }

  function addIdAttribute(htmlString, idValue) {
    const tagsToModify = [
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "p",
      "a",
      "span",
      "input",
      "hr",
      "nav",
      "section",
      "header",
      "footer",
      "button",
      "iframe",
      "img",
      "dt",
      "ol",
      "li",
      "dl",
      "dd",
    ];
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    for (const tag of tagsToModify) {
      const elements = doc.querySelectorAll(tag);
      for (const element of elements) {
        element.setAttribute("id", idValue);
      }
    }

    // Add id to div elements containing the substring "container"
    const divElements = doc.querySelectorAll("div");
    for (const div of divElements) {
      const divName = div.getAttribute("name");
      if (
        divName &&
        (divName.includes("container") || divName.includes("block"))
      ) {
        div.setAttribute("id", idValue);
      }
    }
    var htmlString_ = doc.documentElement.outerHTML;
    htmlString_ = htmlString_.replace(/<html>/gi, "");
    htmlString_ = htmlString_.replace(/<\/html>/gi, "");
    htmlString_ = htmlString_.replace(/<body>/gi, "");
    htmlString_ = htmlString_.replace(/<\/body>/gi, "");
    htmlString_ = htmlString_.replace(/<head>/gi, "");
    htmlString_ = htmlString_.replace(/<\/head>/gi, "");

    return htmlString_;
  }

  function replaceIds(inputString, variable, startNum = 1) {
    let num = startNum;
    const regex = /@id/g;
    const replacedString = inputString.replace(regex, () => {
      const replacement = `${variable}_${num}`;
      num++; // Increment the number
      return replacement;
    });
    return replacedString;
  }

  const [fields, setFields] = useState({
    sectionType: null,
    sectionId: null,
    links: [],
    paragraphs: [],
    headings: [],
    images: [],
  });

  function updateDataArray() {
    setData__((prevData) => {
      return prevData.map((element) => {
        if (element._id === fields["sectionId"]) {
          const tempContainer = document.createElement("div");
          tempContainer.innerHTML = element.htmlTemplate;

          if (element) {
            // Update paragraphs
            fields.paragraphs.forEach((paragraphContent, index) => {
              const paragraphElement = tempContainer.querySelector(
                `p:nth-of-type(${index + 1})`
              );
              if (paragraphElement) {
                paragraphElement.textContent = paragraphContent;
              }
            });

            // Update headings
            fields.headings.forEach((headingContent, index) => {
              const headingElement = tempContainer.querySelector(
                `h1:nth-of-type(${index + 1}), h2:nth-of-type(${
                  index + 1
                }), h3:nth-of-type(${index + 1}), h4:nth-of-type(${
                  index + 1
                }), h5:nth-of-type(${index + 1}), h6:nth-of-type(${index + 1})`
              );
              if (headingElement) {
                headingElement.textContent = headingContent;
              }
            });

            // Update images
            fields.images.forEach((imageSrc, index) => {
              const imageElement = tempContainer.querySelector(
                `img:nth-of-type(${index + 1})`
              );
              if (imageElement) {
                imageElement.src = imageSrc;
              }
            });

            // Update links
            fields.links.forEach((linkData, index) => {
              const linkElement = tempContainer.querySelector(
                `a:nth-of-type(${index + 1})`
              );
              if (linkElement) {
                linkElement.textContent = linkData.content;
                linkElement.href = linkData.href;
              }
            });

            // Return a new element object with updated htmlTemplate
            return {
              ...element,
              htmlTemplate: tempContainer.innerHTML,
            };
          }
        }
        // If element doesn't match the sectionId, return it unchanged
        return element;
      });
    });
  }

  function setSectionSelected(type, id) {
    setFields((prevState) => ({
      ...prevState,
      sectionType: type,
      sectionId: id,
    }));

    data__.forEach((element) => {
      if (element._id === id) {
        if (element) {
          const tempContainer = document.createElement("div");
          tempContainer.innerHTML = element.htmlTemplate;

          // Handling paragraphs
          const paragraphsData = [];
          tempContainer.querySelectorAll("p").forEach((paragraph, index) => {
            paragraphsData.push(paragraph.textContent.trim());
            console.log(paragraph.classList);
          });

          // Handling headings
          const headingsData = [];
          tempContainer
            .querySelectorAll("h1, h2, h3, h4, h5, h6")
            .forEach((heading, index) => {
              headingsData.push(heading.textContent.trim());
            });

          // Handling images
          const imagesData = [];
          tempContainer.querySelectorAll("img").forEach((image, index) => {
            const src = image.getAttribute("src");
            imagesData.push(src);
          });

          const linksData = [];
          tempContainer.querySelectorAll("a").forEach((link, index) => {
            const content = link.textContent.trim();
            const href = link.getAttribute("href");
            linksData.push({ content, href });
          });

          setFields((prevState) => ({
            ...prevState,
            paragraphs: paragraphsData,
            headings: headingsData,
            images: imagesData,
            links: linksData,
          }));
        }
      }
    });
  }

  const handleInputChange = (event, index, key, fieldType) => {
    const { name, value } = event.target;
    const updatedFields = { ...fields };

    if (fieldType === "links") {
      updatedFields.links[index][name] = value; // Update the value directly using 'name'
    } else if (fieldType === "paragraphs") {
      updatedFields.paragraphs[index] = value;
    } else if (fieldType === "headings") {
      updatedFields.headings[index] = value;
    } else if (fieldType === "images") {
      updatedFields.images[index] = value;
    }

    setFields(updatedFields);
  };

  function openWindowWithHtml(htmlContent) {
    // Open a new window
    const newWindow = window.open("", "_blank");
    // Write the HTML content to the new window
    newWindow.document.write(htmlContent);
    // Close the document for the new window
    newWindow.document.close();
  }

  const [undoClicked, setUndoClicked] = useState(0);
  const [curr, setCurr] = useState(0);

  const [isSavingState, setIsSavingState] = useState(false);

  const handleSaveState = async (data) => {
    setIsSavingState(true);
    dispatch(savePageState({ pageIndex: page_index, pageState: data }));
    setTimeout(() => {
      setIsSavingState(false);
    }, 2000);
  };

  const [domainData, setDomainData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!domain) return;

      const { data: websites, error } = await supabase
        .from("websites") // Replace with your actual table name
        .select("*")
        .eq("domain", domain);

      if (error) {
        console.error("Error fetching data:", error);
        return;
      }

      setDomainData(websites);
    };

    fetchData();
  }, []);

  useEffect(() => {
    setKey((prevKey) => prevKey + 1); // Force update
  }, [data__]);

  const [key, setKey] = useState(0);

  const [fullScreenMode, setFullScreenMode] = useState(false);

  const variants = {
    initial: { opacity: 0, x: "-100%" }, // Start from the right side off-screen
    animate: { opacity: 1, x: 0 }, // Move to the original position
    exit: { opacity: 0.5, x: "100%" }, // Move back to the right side off-screen
  };

  const dupdupdup4455 = (inputValue, index) => {
    console.log("dupdupdup");
    const payload = { index: index, dupId: inputValue };
    dispatch(insertDuplicateHtml(payload));
  };

  useEffect(() => {
    const handleIframeMessage = (event) => {
      if (event.origin !== window.location.origin) {
        return;
      }

      if (event.data.action === "callDupdupdup4455") {
        const data = event.data.value;
        dupdupdup4455(data?.inputValue, data?.index); // Call the React function with the value
      }
    };
    window.addEventListener("message", handleIframeMessage);
    return () => {
      window.removeEventListener("message", handleIframeMessage);
    };
  }, []);

  const [alertMessage, setAlertMessage] = useState({
    display: "hidden",
    message: "Your website is not saved successfully",
    messageType: "ERROR",
  });
  return (
    <>
      {alertMessage.display === "flex" && (
        <AlertMessage
          alertMessage={alertMessage}
          setAlertMessage={setAlertMessage}
        />
      )}

      <div className="flex flex-row relative justify-start min-h-screen bg-white overflow-hidden ">
        {data__ && !fullScreenMode && (
          <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.1, ease: "easeInOut" }} // Simple easing
            variants={variants}
            className="h-[100vh]  w-[22%] border-r border-r-gray-100 "
          >
            <Sidebar
              handleSaveState={() => {
                handleSaveState(data__);
              }}
              key={key}
              data_section={data__}
              setData={setData__}
              pages={pages__}
              photos={null}
              setSectionSelected={setSectionSelected}
              setIsSectionSeparatorOn={setIsSectionSeparatorOn}
            />
          </motion.div>
        )}

        <div
          className={` relative flex flex-col items-center w-screen h-screen `}
        >
          {data__ && (
            <div className="bg-white h-[70px] border-b border-b-gray-100 py-2 px-4 w-full flex flex-row justify-between  items-center absolute ">
              <div className="w-auto flex flex-row justify-center items-center gap-2">
                {!fullScreenMode ? (
                  <button
                    onClick={() => {
                      setFullScreenMode(!fullScreenMode);
                    }}
                    className={`flex items-center justify-center  w-[40px] h-[40px] rounded-md hover:bg-black group bg-[#f4f4f4] `}
                  >
                    <RiFullscreenLine
                      className={` group-hover:text-white text-2xl text-black`}
                    />
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setFullScreenMode(!fullScreenMode);
                    }}
                    className={`flex items-center justify-center  w-[40px] h-[40px] rounded-md hover:bg-black group bg-[#f4f4f4]`}
                  >
                    <TbApps
                      className={` group-hover:text-white text-2xl text-black`}
                    />
                  </button>
                )}

                <button
                  onClick={() => {
                    dispatch(undo());
                  }}
                  className="flex items-center justify-center  bg-[#f4f4f4] w-[40px] h-[40px] rounded-md hover:bg-black group "
                >
                  <LiaUndoAltSolid className="text-black group-hover:text-white text-2xl" />
                </button>

                {isSectionSeparatorOn ? (
                  <button
                    onClick={() => {
                      setIsSectionSeparatorOn(false);
                      dispatch(removeLineSections());
                    }}
                    className={`flex items-center justify-center cursor-pointer ${
                      !isSectionSeparatorOn
                        ? "bg-[#f4f4f4] text-black"
                        : "bg-black text-white"
                    } w-[40px] h-[40px] rounded-md hover:bg-black group text-black hover:text-white text-xl `}
                  >
                    -
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setIsSectionSeparatorOn(true);
                      dispatch(insertSectionWithLine());
                    }}
                    className="flex items-center justify-center cursor-pointer  bg-[#f4f4f4] w-[40px] h-[40px] rounded-md hover:bg-black group text-black hover:text-white text-xl "
                  >
                    +
                  </button>
                )}

                <button
                  onClick={() => {
                    setWireMode(0);
                  }}
                  className={` group px-3 py-1 flex hover:bg-black hover:text-white items-center rounded-md justify-center w-auto ease-in-out duration-200 transition-all   ${
                    wireMode === 0
                      ? "bg-black text-white"
                      : "bg-[#f4f4f4] text-black"
                  }  h-[40px]  `}
                >
                  View
                </button>
                <button
                  onClick={() => {
                    setWireMode(1);
                  }}
                  className={`px-3  font_inter flex hover:bg-black hover:text-white  rounded-md items-center justify-center w-auto ease-in-out duration-200 transition-all  ${
                    wireMode === 1
                      ? "bg-black text-white"
                      : "bg-[#f4f4f4] text-black"
                  }  h-[40px] `}
                >
                  Element
                </button>

                <button
                  onClick={() => {
                    setWireMode(3);
                  }}
                  className={`px-3 font_inter flex hover:bg-black  hover:text-white rounded-md items-center justify-center w-auto  ease-in-out duration-200 transition-all  ${
                    wireMode === 3
                      ? "bg-black text-white"
                      : "bg-[#f4f4f4] text-black"
                  }  h-[40px] `}
                >
                  Container
                </button>
              </div>

              {domainData && domainData?.length > 0 && (
                <h1 className="text-gray-400 font_inter text-sm">{`https://${domainData[0]?.primaryDomain}`}</h1>
              )}
              <div className="w-auto flex flex-row justify-center items-center gap-2">
                <div className="w-auto flex flex-row justify-center items-center">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={mobileMode}
                      onChange={setPreview}
                    />
                    <span className="slider"></span>
                  </label>
                  <div className="absolute flex flex-row justify-center items-center gap-5">
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        setPreview();
                      }}
                    >
                      <svg
                        width="22"
                        height="20"
                        viewBox="0 0 24 23"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22.9167 0H1.04167C0.7654 0 0.500448 0.109747 0.305097 0.305097C0.109747 0.500447 0 0.7654 0 1.04167V13.5417H23.9583V1.04167C23.9583 0.7654 23.8486 0.500447 23.6532 0.305097C23.4579 0.109747 23.1929 0 22.9167 0Z"
                          fill="black"
                        />
                        <path
                          d="M0 16.6663C0 16.9426 0.109747 17.2076 0.305097 17.4029C0.500448 17.5983 0.7654 17.708 1.04167 17.708H22.9167C23.1929 17.708 23.4579 17.5983 23.6532 17.4029C23.8486 17.2076 23.9583 16.9426 23.9583 16.6663V14.583H0V16.6663ZM11.9792 15.6247C12.1173 15.6247 12.2498 15.6795 12.3475 15.7772C12.4451 15.8749 12.5 16.0074 12.5 16.1455C12.5 16.2836 12.4451 16.4161 12.3475 16.5138C12.2498 16.6115 12.1173 16.6663 11.9792 16.6663C11.841 16.6663 11.7086 16.6115 11.6109 16.5138C11.5132 16.4161 11.4583 16.2836 11.4583 16.1455C11.4583 16.0074 11.5132 15.8749 11.6109 15.7772C11.7086 15.6795 11.841 15.6247 11.9792 15.6247Z"
                          fill="black"
                        />
                        <path
                          d="M16.1452 21.875H15.1035V18.75H8.85352V21.875H7.81185C7.67372 21.875 7.54124 21.9299 7.44356 22.0275C7.34589 22.1252 7.29102 22.2577 7.29102 22.3958C7.29102 22.534 7.34589 22.6664 7.44356 22.7641C7.54124 22.8618 7.67372 22.9167 7.81185 22.9167H16.1452C16.2833 22.9167 16.4158 22.8618 16.5135 22.7641C16.6111 22.6664 16.666 22.534 16.666 22.3958C16.666 22.2577 16.6111 22.1252 16.5135 22.0275C16.4158 21.9299 16.2833 21.875 16.1452 21.875Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        setPreview();
                      }}
                    >
                      <img
                        className="h-[22px] w-[22px]"
                        src={phone}
                        alt="phone"
                      />
                    </div>
                  </div>
                </div>
                <Publish
                  alertMessage={alertMessage}
                  setAlertMessage={setAlertMessage}
                  userId={credId}
                />
              </div>
            </div>
          )}

          <div style={containerStyles} className="h-full w-full">
            {data__ ? (
              <iframe
                ref={iframeRef}
                title="My Site"
                id="fr"
                className="custom-scrollbar mt-[70px]   "
                srcDoc={`${SourceDocument(pageHtml, functionalScript)}`}
                style={{ width: "100%", height: "100%", border: "none" }}
              ></iframe>
            ) : (
              <iframe
                ref={iframeRef}
                id="fr"
                title="My Site"
                className="custom-scrollbar rounded-tl-xl rounded-tr-xl shadow-lg"
                srcDoc={`
                 <!DOCTYPE html>
                <html>
                <head>
                <meta charset="utf-8" />
                </head>
                <body>
                <div class="w-full h-[98vh] mt-[10px] bg-white flex flex-col items-center justify-center">
                  <h1>Cannot Load the Page Properly. ${error} . Reload the page</h1>
                </div>
                </body>
                
                `}
                style={{ width: "100%", height: "100%", border: "none" }}
              ></iframe>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Mainpage;
