import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setIdea } from "../../Store/Slices/IdeaSlice";
import { setDomain, setName_ } from "../../Store/Slices/NameSlice";
import {
  setImageQuery,
  setImages,
  setUsed,
} from "../../Store/Slices/ImageQuerySlice";
import {
  setColorArray,
  setFonts,
  setHue,
  setSiteId,
} from "../../Store/Slices/ColorSlice";
import {
  initalizeUserSlice,
  setPageHtmlState,
} from "../../Store/Slices/userHtmlSlice";
import { setSectionArray } from "../../Store/Slices/SectionSlice";
import { useNavigate, useParams } from "react-router-dom";
import "../Builder_prompt_components/global_theme/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import supabase from "../../config/supabse";
import "./spinner.css";

function LoadingData(props) {
  const { credId, token, linkId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  var webPage = [];
  function manipulate_section_string(_id, htmlTemplate) {
    var htmlStr = htmlTemplate;

    if (htmlStr.startsWith('"') && htmlStr.endsWith('"')) {
      htmlStr = htmlStr.slice(1, -1);
    }
    if (htmlStr.startsWith("'") && htmlStr.endsWith("'")) {
      htmlStr = htmlStr.slice(1, -1);
    }
    webPage.push(htmlStr);
  }

  useEffect(() => {
    const fetchData = async (userCrendentialId, token) => {
      console.log("Loading Start....");
      try {
        console.log("Loading Start  2 ....");
        // Attempt to fetch data where deployedVersion is true
        const { data, error } = await supabase
          .from("histories")
          .select("*")
          .eq("userId", userCrendentialId)
          .eq("deployedVersion", true)
          .order("created_at", { ascending: false }) // Order by created_at in descending order
          .limit(1); // Get the most recent row
        if (error) {
          console.log("Loading Start  errr ....");
          // console.log("Error fetching deployed version:", error);
          // // If there's an error, fetch the most recent row as a fallback
          // ({ data, error } = await supabase
          //   .from("histories")
          //   .select("*")
          //   .eq("userId", userCrendentialId)
          //   .order("created_at", { ascending: false })
          //   .limit(1));

          // if (error) {
          //   console.log("Loading Start errr 2 ....");
          //   console.error("Error fetching most recent row:", error);
          //   alert("Try Again Later");
          //   return;
          // }
        } else if (data && data.length > 0) {
          console.log("Loading Start  3 ....");
          console.log("UserData:", data);
          const row = data[0];

          // Dispatch necessary actions
          row.webIdea && dispatch(setIdea(row.webIdea));
          row.webName && dispatch(setName_(row.webName));
          row.webColor && dispatch(setColorArray(row.webColor));
          row.hue && dispatch(setHue(row.hue));
          row.webTheme?.fonts && dispatch(setFonts(row.webTheme.fonts));
          row.siteId && dispatch(setSiteId(row.siteId));

          if (Array.isArray(row?.userAssets?.queries)) {
            const firstFiveEntries = row?.userAssets?.queries.slice(0, 5);
            dispatch(setImageQuery(firstFiveEntries));
          } else {
            console.error("queries is not an array");
          }

          if (row.userAssets?.images?.length > 0) {
            dispatch(setImages(row.userAssets.images));
          } else {
            dispatch(setImages(row.userAssets?.used || []));
          }

          row.userAssets?.used && dispatch(setUsed(row.userAssets.used));
          row.domain && dispatch(setDomain(row.domain));
          row.userData && dispatch(initalizeUserSlice(row.userData));
          row.webSectionType && dispatch(setSectionArray(row.webSectionType));

          const data__ = row?.userData["Home"];
          if (data__ && data__?.length > 0) {
            for (const element of data__) {
              manipulate_section_string(element._id, element.htmlTemplate);
            }
            const html = webPage.join("");
            console.log("THE HTML COMBINED FOR SS =", html);
            dispatch(setPageHtmlState(html));
          }

          console.log("The linkId =", linkId);
          if (parseInt(linkId) === 1) {
            navigate(`/main/${userCrendentialId}`);
          } else {
            navigate(`/dashboard/${userCrendentialId}`);
          }
        } else if (token !== "12988") {
          console.log("Idea redirecting");
          navigate(`/idea/${userCrendentialId}/${token}`);
        }
      } catch (error) {
        console.error("Error in fetchData:", error);
      }
    };

    fetchData(credId, token);
  }, []);

  return (
    <>
      <div className="flex items-center justify-center min-h-screen flex-col">
        <div className="spinner"></div>
        <div>Loading...</div>
      </div>
    </>
  );
}

export default LoadingData;
