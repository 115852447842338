import React, { useEffect, useState } from "react";
import supabase from "../../../config/supabse";

import "../css/checkbox.css";
function PricingPlans() {
  const [id, setId] = useState(null);
  const [auth, setAuth] = useState(false);
  const [email, setEmail] = useState("");
  const MODE = "production";

  useEffect(() => {
    const getSession = async () => {
      const { data, error } = await supabase.auth.getSession();
      if (error) {
        console.error("Error retrieving session:", error.message);
        return;
      }
      if (data) {
        if (
          data &&
          data.session &&
          data.session.user.id &&
          data.session.access_token
        ) {
          const email = data.session.user.email;
          const userId = data.session.user.id;

          console.log("In pricing  = > ", email);
          setEmail(email);
          setId(userId);

          setAuth(true);
        }
      }
    };
    getSession();
  }, []);

  const style = {
    background:
      "linear-gradient(180deg, rgba(61, 61, 61, 0.28) 34%, rgb(0, 0, 0) 100%)",
    borderRadius: "20px",
    boxShadow:
      "0 0.796px 2.389px -0.625px #0000000d, 0 2.415px 7.244px -1.25px #0000000d, 0 6.383px 19.148px -1.875px #0000000d, 0 20px 60px -2.5px #0000000d",
  };

  console.log("Process  == = = ", process.env.REACT_APP_ENV);

  const Free = [
    {
      name: "Free",
      paymentLinks: {
        USD: "",
        EURO: "",
        AUD: "",
        GBP: "",
      },
      priceId: {
        USD: "",
        EURO: "",
        AUD: "",
        GBP: "price_1PklbVLif7EqrIP2O1WwDqQy",
      },
      price: {
        USD: "",
        EURO: "",
        AUD: "",
        GBP: 0,
      },
      duration: "month",
    },
    {
      name: "Free",
      paymentLinks: {
        USD: "",
        EURO: "",
        AUD: "",
        GBP: "",
      },
      priceId: {
        USD: "",
        EURO: "",
        AUD: "",
        GBP: "price_1PklcVLif7EqrIP2hQ1QY1bV",
      },
      price: {
        USD: "",
        EURO: "",
        AUD: "",
        GBP: 0,
      },
      duration: "year",
    },
  ];

  const Mini = [
    {
      name: "Mini",
      paymentLinks: {
        USD: "",
        EURO: "",
        AUD: "",
        GBP:
          MODE === "development"
            ? "https://buy.stripe.com/test_14k01E7O0fQZ3K04gk"
            : "https://buy.stripe.com/bIY14Jdxe9zQ1aw4gi",
      },
      priceId: {
        USD: "",
        EURO: "",
        AUD: "",
        GBP: "price_1PklduLif7EqrIP2IkpXB4WS",
      },
      price: {
        USD: "",
        EURO: "",
        AUD: "",
        GBP: 5,
      },
      duration: "month",
    },
    {
      name: "Mini",
      paymentLinks: {
        USD: "",
        EURO: "",
        AUD: "",
        GBP:
          MODE === "development"
            ? "https://buy.stripe.com/test_5kA5lY8S4gV33K07su"
            : "https://buy.stripe.com/14k4gVal2eUa6uQdQR",
      },
      priceId: {
        USD: "",
        EURO: "",
        AUD: "",
        GBP: "price_1PklcxLif7EqrIP22pdra03v",
      },
      price: {
        USD: "",
        EURO: "",
        AUD: "",
        GBP: 50,
      },
      duration: "month",
    },
  ];

  const Basic = [
    {
      name: "Basic",
      paymentLinks: {
        USD: "",
        EURO: "",
        AUD: "",
        GBP:
          MODE === "development"
            ? "https://buy.stripe.com/test_4gw7u67O020994k8wC"
            : "https://buy.stripe.com/fZe3cRal2eUa1aweUY",
      },
      priceId: {
        USD: "",
        EURO: "",
        AUD: "",
        GBP: "price_1PkmaYLif7EqrIP2ft2DIdH1",
      },
      price: {
        USD: "",
        EURO: "",
        AUD: "",
        GBP: 15,
      },
      duration: "month",
    },
    {
      name: "Basic",
      paymentLinks: {
        USD: "",
        EURO: "",
        AUD: "",
        GBP:
          MODE === "development"
            ? "https://buy.stripe.com/test_eVa3dQc4gdIR1BS149"
            : "https://buy.stripe.com/28oaFjal2h2i5qM8wz",
      },
      priceId: {
        USD: "",
        EURO: "",
        AUD: "",
        GBP: "price_1PkmaoLif7EqrIP2sxwWrVPp",
      },
      price: {
        USD: "",
        EURO: "",
        AUD: "",
        GBP: 140,
      },
      duration: "year",
    },
  ];

  const Business = [
    {
      name: "Business",
      paymentLinks: {
        USD: "",
        EURO: "",
        AUD: "",
        GBP:
          MODE === "development"
            ? "https://buy.stripe.com/test_dR615I5FS0W580g3cj"
            : "https://buy.stripe.com/4gwdRv3WE8vMf1m9AH",
      },
      priceId: {
        USD: "",
        EURO: "",
        AUD: "",
        GBP: "price_1Pkmh1Lif7EqrIP2lIDBcMWM",
      },
      price: {
        USD: "",
        EURO: "",
        AUD: "",
        GBP: 25,
      },
      duration: "month",
    },
    {
      name: "Business",
      paymentLinks: {
        USD: "",
        EURO: "",
        AUD: "",
        GBP:
          MODE === "development"
            ? "https://buy.stripe.com/test_6oE9Ce0ly6gpeoEdQY"
            : "https://buy.stripe.com/eVa14J2SA9zQbPa7sy",
      },
      priceId: {
        USD: "",
        EURO: "",
        AUD: "",
        GBP: "price_1PkTZeLif7EqrIP2gy4A3YGY",
      },
      price: {
        USD: "",
        EURO: "",
        AUD: "",
        GBP: 240,
      },
      duration: "year",
    },
  ];

  const [miniPlan, setMiniPlan] = useState(Mini[0]);
  const [basicPlan, setBasicPlan] = useState(Basic[0]);
  const [businessPlan, setBusinessPlan] = useState(Business[0]);

  const [selectedMiniOption, setSelectedMiniOption] = useState(false);
  const [selectedBusinessOption, setSelectedBusinessOption] = useState(false);

  const [freePlan, setFreePlan] = useState(Free[0]);
  const [selectedBasicOption, setSelectedBasicOption] = useState(false);

  const handleOptionMiniChange = (event) => {
    setSelectedMiniOption(!selectedMiniOption);
  };
  const handleOptionBusinessChange = (event) => {
    setSelectedBusinessOption(!selectedBusinessOption);
  };

  const handleOptionBasicChange = (event) => {
    setSelectedBasicOption(!selectedBasicOption);
  };

  useEffect(() => {
    if (selectedMiniOption) {
      setMiniPlan(Mini[1]);
    } else {
      setMiniPlan(Mini[0]);
    }

    if (selectedBasicOption) {
      setBasicPlan(Basic[1]);
    } else {
      setBasicPlan(Basic[0]);
    }

    if (selectedBasicOption) {
      setBasicPlan(Basic[1]);
    } else {
      setBasicPlan(Basic[0]);
    }

    if (selectedBusinessOption) {
      setBusinessPlan(Business[1]);
    } else {
      setBusinessPlan(Business[0]);
    }
  }, [selectedMiniOption, selectedBasicOption, selectedBusinessOption]);

  const [stateDur, setStateDur] = useState("M");
  useEffect(() => {
    if (stateDur === "M") {
      setBasicPlan(Basic[0]);
      setBusinessPlan(Business[0]);
      setMiniPlan(Mini[0]);
    } else if (stateDur === "Y") {
      setBasicPlan(Basic[1]);
      setBusinessPlan(Business[1]);
      setMiniPlan(Mini[1]);
    } else {
      setBasicPlan(Basic[0]);
      setBusinessPlan(Business[0]);
      setMiniPlan(Mini[0]);
    }
  }, [stateDur]);

  return (
    <>
      <div
        className="relative flex items-center justify-center w-full h-auto min-h-[100vh]"
        style={{ backgroundColor: "transparent" }}
      >
        <div className="z-20 flex flex-col items-center justify-center gap-5">
          <div className="flex items-center justify-center flex-col">
            <h2 className="text-3xl font-medium text-center mb-2">
              Get started with our plans today
            </h2>
            <p className="text-center mb-8">
              <a
                href="#"
                className="text-sm hover:underline hover:underline-offset-8 transition-all duration-1000"
              >
                View pricing plans &gt;
              </a>
            </p>
          </div>

          <div className="flex w-[100%] flex-col xl:flex-row items-center justify-center  lg:mt-10  gap-5 overflow-auto">
            <div
              // style={style}
              className="bg-white border-[1px] min-h-[55vh] xl:min-h-[50vh] w-[80vw]  lg:w-[50vw] xl:w-[18vw] rounded-[10px] flex flex-col justify-between p-2"
            >
              <div className="flex flex-col p-3">
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="text-sm font-bold">Free</p>
                </div>

                <h1 className="font-bold text-[56px] tracking-[-5%] mt-4">
                  &pound;{freePlan.price.GBP}{" "}
                  <span className="text-[16px] font-medium">Forever</span>{" "}
                </h1>

                <ul className="flex flex-col gap-1 mt-4">
                  <li className="text-[14px]  mt-2">For Hobby sites</li>
                  <li className="text-[14px] mt-2">HelloWorld Domain</li>
                  <li className="text-[14px]  mt-2">HelloWorld banner</li>
                </ul>
              </div>

              {!auth ? (
                <button
                  onClick={() => {
                    alert("Please login first");
                  }}
                  className="bg-white text-center text-black font-bold text-[15px] flex items-center justify-center justify-self-end rounded-[5px] py-2 h-[60px]"
                >
                  Login & Get Plan
                </button>
              ) : (
                <a
                  target="_blank"
                  href={
                    miniPlan.paymentLinks.GBP +
                    "?prefilled_email=" +
                    email +
                    "&client_reference_id=" +
                    id
                  }
                  className="bg-white text-center text-black font-bold text-[15px] flex items-center justify-center justify-self-end rounded-[5px] py-2 h-[60px]"
                >
                  Get Site Plan
                </a>
              )}
            </div>

            <div
              // style={style}
              className="bg-black border-[1px] min-h-[55vh] xl:min-h-[50vh] w-[80vw] lg:w-[50vw] xl:w-[18vw] rounded-[10px] flex flex-col justify-between p-2"
            >
              <div className="flex flex-col p-3">
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="text-sm font-bold text-white">Mini</p>
                </div>

                <h1 className="font-bold text-white text-[56px] tracking-[-5%] mt-4">
                  &pound;{miniPlan.price.GBP}{" "}
                  <span className="text-[16px] font-medium text-white">
                    per {miniPlan.duration}
                  </span>{" "}
                </h1>

                <ul className="flex flex-col gap-1 mt-4">
                  <li className="text-[14px] text-white  mt-2">
                    Custom domain Configuration
                  </li>
                  <li className="text-[14px]  text-white mt-2">
                    Mobile Optimized
                  </li>
                  <li className="text-[14px] text-white  mt-2">1 Page</li>
                </ul>
              </div>

              {!auth ? (
                <button
                  onClick={() => {
                    alert("Please login first");
                  }}
                  className="bg-white text-center text-black font-bold text-[15px] flex items-center justify-center justify-self-end rounded-[5px] py-2 h-[60px]"
                >
                  Login & Get Plan
                </button>
              ) : (
                <a
                  target="_blank"
                  href={
                    miniPlan.paymentLinks.GBP +
                    "?prefilled_email=" +
                    email +
                    "&client_reference_id=" +
                    id
                  }
                  className="bg-white text-center text-black font-bold text-[15px] flex items-center justify-center justify-self-end rounded-[5px] py-2 h-[60px]"
                >
                  Get Site Plan
                </a>
              )}
            </div>

            <div className="bg-black border-[#ffffff1a] border-[1px] min-h-[55vh] xl:min-h-[50vh] w-[80vw]  lg:w-[50vw] xl:w-[18vw] rounded-[10px] flex flex-col justify-between p-2">
              <div className="flex flex-col p-3">
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="text-sm font-bold text-white">Basic</p>
                </div>
                <h1 className="font-bold text-white text-[56px] tracking-[-5%] mt-4">
                  &pound;{basicPlan.price.GBP}{" "}
                  <span className="text-[16px] font-medium text-white">
                    per {basicPlan.duration}
                  </span>{" "}
                </h1>

                <ul className="flex flex-col gap-1 mt-4">
                  <li className="text-[14px] text-white  mt-2">
                    Custom domain Configuration
                  </li>
                  <li className="text-[14px] text-white mt-2">
                    Mobile Optimized
                  </li>

                  <li className="text-[14px] text-white  mt-2">4 Pages</li>
                  <li className="text-[14px] text-white  mt-2">
                    50 Form Submissions per Month
                  </li>
                </ul>
              </div>

              {!auth ? (
                <button
                  onClick={() => {
                    alert("Please login first");
                  }}
                  className="bg-white text-center text-black font-bold text-[15px] flex items-center justify-center justify-self-end rounded-[5px] py-2 h-[60px]"
                >
                  Login & Get Plan
                </button>
              ) : (
                <a
                  target="_blank"
                  href={
                    basicPlan.paymentLinks.GBP +
                    "?prefilled_email=" +
                    email +
                    "&client_reference_id=" +
                    id
                  }
                  className="bg-white text-center text-black font-bold text-[15px] flex items-center justify-center justify-self-end rounded-[5px] py-2 h-[60px]"
                >
                  Get Site Plan
                </a>
              )}
            </div>

            <div className="bg-black border-[#ffffff1a] border-[1px] min-h-[55vh] xl:min-h-[50vh] w-[80vw] lg:w-[50vw] xl:w-[18vw] rounded-[10px] flex flex-col justify-between p-2">
              <div className="flex flex-col p-5">
                <div className="flex flex-row items-center justify-between w-full">
                  <p className="text-sm font-bold text-white">Business</p>
                </div>
                <h1 className="font-bold text-white text-[56px] tracking-[-5%] mt-4">
                  &pound;{businessPlan.price.GBP}{" "}
                  <span className="text-[16px] font-medium text-white">
                    per {businessPlan.duration}
                  </span>{" "}
                </h1>

                <ul className="flex flex-col gap-1 mt-4">
                  <li className="text-[14px] text-white  mt-2">
                    Custom domain Configuration
                  </li>
                  <li className="text-[14px] text-white mt-2">
                    Mobile Optimized
                  </li>

                  <li className="text-[14px] text-white  mt-2">
                    Unlimited Pages
                  </li>
                  <li className="text-[14px] text-white  mt-2">
                    150 Form Submissions per Month
                  </li>
                  <li className="text-[14px] text-white  mt-2">
                    Customer Relationship Management
                  </li>
                  <li className="text-[14px] text-white  mt-2">
                    AI-Powered SEO
                  </li>
                </ul>
              </div>

              {!auth ? (
                <button
                  onClick={() => {
                    alert("Please login first");
                  }}
                  className="bg-white text-center text-black font-bold text-[15px] flex items-center justify-center justify-self-end rounded-[5px] py-2 h-[60px]"
                >
                  Login & Get Plan
                </button>
              ) : (
                <a
                  target="_blank"
                  href={
                    businessPlan.paymentLinks.GBP +
                    "?prefilled_email=" +
                    email +
                    "&client_reference_id=" +
                    id
                  }
                  className="bg-white text-center text-black font-bold text-[15px] flex items-center justify-center justify-self-end rounded-[5px] py-2 h-[60px]"
                >
                  Get Site Plan
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PricingPlans;
