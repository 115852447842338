import React, { useState } from "react";
import "./Signin.css";
import "../Builder_prompt_components/global_theme/fonts.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import video from "../../assets/video.mp4";
import supabase from "../../config/supabse";
import { useNavigate } from "react-router-dom";

const EmailPass = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false); // Toggle login/signup

  // Handles Sign Up & Login
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!email.trim() || !password.trim()) {
      showRejectMessage("Email and Password are required");
      setLoading(false);
      return;
    }

    try {
      let response;

      if (isSignUp) {
        // Check if the user already exists
        const { data: userExists, error: userCheckError } =
          await supabase.auth.signInWithOtp({ email });

        if (userCheckError) {
          throw userCheckError;
        }

        if (userExists) {
          // If user exists, update password
          response = await supabase.auth.updateUser({
            password,
          });

          if (response.error) throw response.error;

          showSuccessMessage("Password updated! You can now log in.");
          setIsSignUp(false); // Switch to login mode
        } else {
          // If user does not exist, proceed with sign-up
          response = await supabase.auth.signUp({ email, password });

          if (response.error) throw response.error;

          showSuccessMessage(
            "Account created! Please check your email to verify."
          );
        }
      } else {
        // Login
        response = await supabase.auth.signInWithPassword({
          email,
          password,
        });

        if (response.error) throw response.error;

        showSuccessMessage("Logged in successfully!");

        navigate(`/`);
      }
    } catch (error) {
      showRejectMessage(error.message || "An error occurred");
    }

    setLoading(false);
  };

  const showSuccessMessage = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const showRejectMessage = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  return (
    <div className="flex w-full h-screen overflow-y-hidden">
      {/* Video Background (Only on Large Screens) */}
      <div className="relative hidden w-2/3 h-full overflow-hidden xl:flex">
        <div className="absolute inset-0 z-[-1] overflow-hidden">
          <video
            src={video}
            autoPlay
            loop
            muted
            className="object-cover w-full h-full"
          />
        </div>
      </div>

      {/* Login/Signup Form */}
      <div className="flex items-center justify-center w-full bg-black xl:w-1/3">
        <div className="w-4/5 bg-black border-0 rounded-lg">
          <h2 className="mb-1 text-3xl font-bold text-white manrope-400">
            HelloWorld
          </h2>
          <p className="text-zinc-300 manrope-400">
            {isSignUp
              ? "Create an account or update your password"
              : "Enter your email and password to log in"}
          </p>

          <form className="space-y-4 mt-7" onSubmit={handleSubmit}>
            <input
              className="w-full h-[45px] rounded-[10px] border border-input bg-background px-3 py-2 text-sm focus:ring-2 focus:ring-gray-400"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
            />

            <input
              className="w-full h-[45px] rounded-[10px] border border-input bg-background px-3 py-2 text-sm focus:ring-2 focus:ring-gray-400"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
            />

            <button
              type="submit"
              className={`w-full h-[45px] flex justify-center items-center text-sm font_inter rounded-[10px] ${
                loading
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-[#FFD488] hover:bg-[#e9c78e]"
              }`}
              disabled={loading}
            >
              {loading
                ? "Processing..."
                : isSignUp
                ? "Sign Up / Update Password"
                : "Login"}
            </button>
          </form>

          {/* Toggle between login/signup */}
          <p
            className="mt-4 text-sm text-center text-gray-400 cursor-pointer"
            onClick={() => setIsSignUp(!isSignUp)}
          >
            {isSignUp
              ? "Already have an account? Log in"
              : "Don't have an account or forgot password? Sign up / Update password"}
          </p>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default EmailPass;
