import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import store from "../src/Store/Store";
import { persistor } from "../src/Store/Store";
import Name from "./components/Builder_prompt_components/Name/Name";
import LogoUpload from "./components/Builder_prompt_components/logo/LogoUpload";
import Progress from "./components/Builder_prompt_components/Progress/Progress";
import Mainpage from "./components/MainPage/Mainpage";
import Idea from "./components/Builder_prompt_components/Idea/Idea";
import { PersistGate } from "redux-persist/integration/react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import LoadingData from "./components/SignIn/LoadingData";
import Webpage from "./components/MainPage/Webpage";
import Admin from "./components/Admin/Admin";
import UserPlan from "./components/Builder_prompt_components/userPlan/UserPlan";
import DashboardHome from "./components/Dashboard/DashboardPages/DashboardHome";
import RootLayout from "./components/Dashboard/DashboardLayout/RootLayout";
import WebsiteOverview from "./components/Dashboard/DashboardPages/WebsiteOverview";
import WebsiteDomain from "./components/Dashboard/DashboardComponents/Domain/WebsiteDomain";
import SettingStatus from "./components/Dashboard/DashboardComponents/WebsiteSettings/SettingsStatus/SettingStatus";
import CRM from "./components/Dashboard/DashboardPages/CRM";
import Status from "./components/Dashboard/DashboardPages/Status";
import Analytics from "./components/Dashboard/DashboardPages/Analytics";
import ContactDetails from "./components/Dashboard/DashboardPages/ContactDetails";
import Domain from "./components/Dashboard/DashboardPages/Domain";
import Settings from "./components/Dashboard/DashboardPages/Settings";
import Plans from "./components/Dashboard/DashboardPages/Plans";
import PricingPage from "./components/HelloWorldWeb/pages/PricingPage";
import AboutUs from "./components/HelloWorldWeb/pages/AboutUs";
import PrivacyPolicy from "./components/HelloWorldWeb/pages/PrivacyPolicy";
import Signin from "./components/SignIn/Signin";
import UpgradePlan from "./components/Dashboard/DashboardComponents/UpgradePlan/UpgradePlan";
import Billing from "./components/Dashboard/DashboardComponents/Billing/Billing";
import SuccessScreen from "./components/PaymentScreens/SuccessScreen";
import ManageSubscription from "./components/Dashboard/DashboardComponents/Billing/ManageSubscription";
import TakingYouToEditor from "./components/Builder_prompt_components/Progress/TakingYouToEditor";
import ContactDetailCard from "./components/Dashboard/DashboardComponents/Contact/ContactDetailCard";
import AdminEditor from "./components/Dashboard/DashboardPages/AdminEditor";
import EmailPass from "./components/SignIn/EmailPass";

const Root = () => {
  const router = createBrowserRouter([
    {
      path: "/dashboard/:credId/",
      element: <RootLayout />,
      children: [
        {
          path: "/dashboard/:credId/",
          element: <DashboardHome />,
        },
        {
          path: "/dashboard/:credId/overview",
          element: <WebsiteOverview />,
        },
        {
          path: "/dashboard/:credId/domain",
          element: <Domain />,
        },
        {
          path: "/dashboard/:credId/settings",
          element: <Settings />,
        },
        {
          path: "/dashboard/:credId/settings-status",
          element: <Status />,
        },
        {
          path: "/dashboard/:credId/crm",
          element: <CRM />,
        },
        {
          path: "/dashboard/:credId/contact-details",
          element: <ContactDetails />,
        },
        {
          path: "/dashboard/:credId/contact-details/:email",
          element: <ContactDetailCard />,
        },

        {
          path: "/dashboard/:credId/analytics",
          element: <Analytics />,
        },

        {
          path: "/dashboard/:credId/billing",
          element: <Billing />,
        },
        {
          path: "/dashboard/:credId/upgrade-plan",
          element: <UpgradePlan />,
        },
        {
          path: "/dashboard/:credId/admin-editor",
          element: <AdminEditor />,
        },
      ],
    },

    {
      path: "/",
      element: <App />,
    },
    {
      path: "/payment/success",
      element: <SuccessScreen />,
    },
    {
      path: "/about-us",
      element: <AboutUs />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/admin/matt-022ab3345/334jrteepac",
      element: <Admin />,
    },
    {
      path: "/pricing/:credId",
      element: <PricingPage />,
    },
    {
      path: "/pricing",
      element: <PricingPage />,
    },
    {
      path: "/idea/:credId/:token",
      element: <Idea />,
    },
    {
      path: "/loading/:credId/:token/:linkId",
      element: <LoadingData />,
    },
    {
      path: "/name/:credId/:token",
      element: <Name />,
    },
    {
      path: "/yourplan/:credId/:token",
      element: <UserPlan />,
    },
    {
      path: "/logo/:credId/:token",
      element: <LogoUpload />,
    },
    {
      path: "/progress/:credId/:token",
      element: <Progress />,
    },
    {
      path: "/main/:credId",
      element: <Mainpage />,
    },
    {
      path: "/signin",
      element: <Signin />,
    },
    {
      path: "/email-pass",
      element: <EmailPass />,
    },
    {
      path: "/site",
      element: <Webpage />,
    },
    {
      path: "/redirecting/:credId",
      element: <TakingYouToEditor />,
    },
    {
      path: "*",
      element: <h1>404 Page Not Found</h1>, // You can replace NotFoundPage with your custom component for handling unknown paths
    },

    {
      path: "/manage-subscription/:credId",
      element: <ManageSubscription />, // You can replace NotFoundPage with your custom component for handling unknown paths
    },
  ]);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <GoogleOAuthProvider
          clientId={`${process.env.REACT_APP_GOOGLE_API_TOKEN}`}
        >
          <RouterProvider router={router} />
        </GoogleOAuthProvider>
      </PersistGate>
    </Provider>
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(<Root />);
