import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../../SignIn/spinner.css";
import supabase from "../../../../config/supabse";
import { useSelector } from "react-redux";
import UpsertPlan from "../UpsertPlan/UpsertPlan";
import { MdOutlineFileDownload } from "react-icons/md";
const CRMMain = () => {
  const { credId } = useParams();
  const navigate = useNavigate();
  const Name = useSelector((state) => {
    return state.Name.data;
  });
  const subDomain = useSelector((state) => {
    return state.Name.subdomain;
  });
  const [data, setData] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const formatDateTime = (timestamp) => {
    const date = new Date(timestamp);

    // Format date as "August 9, 2024"
    const dateFormatter = new Intl.DateTimeFormat("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });

    // Format time as "6:00 PM"
    const timeFormatter = new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return {
      formattedDate: dateFormatter.format(date),
      formattedTime: timeFormatter.format(date),
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data: fetchedData, error } = await supabase
          .from("userMessages") // Replace with your table name
          .select("*")
          .eq("siteId", `${subDomain}`); // Replace 'domain' with your column name
        if (error) throw error;

        const formattedMessages = fetchedData.map((message) => {
          const { formattedDate, formattedTime } = formatDateTime(
            message.created_at
          );
          return {
            id: message.id,
            firstName: message.messageBody["first-name"],
            lastName: message.messageBody["last-name"],
            body: message.messageBody["message"],
            email: message.messageBody["email"],
            phone: message.messageBody["phone"],
            stamp: `${formattedDate} at ${formattedTime}`,
            formattedDate, // Add formattedDate
            formattedTime, // Add formattedTime
          };
        });

        setData(formattedMessages);
        console.log("CRM data", fetchedData);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [downloadingCSV, setDownloadingCSV] = useState(false);

  const entriesPerPage = 10;

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
    setCurrentPage(1); // Reset to first page on search
  };

  const filteredData = data.filter((item) =>
    item?.email?.toLowerCase().includes(searchTerm)
  );

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredData.slice(
    indexOfFirstEntry,
    indexOfLastEntry
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(filteredData?.length / entriesPerPage);

  const handleCheckboxChange = (itemId) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.includes(itemId)
        ? prevSelectedItems.filter((id) => id !== itemId)
        : [...prevSelectedItems, itemId]
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedItems([]);
    } else {
      setSelectedItems(currentEntries.map((item) => item.id));
    }
    setSelectAll(!selectAll);
  };

  const convertToCSV = (array) => {
    if (!array?.length) return "";

    // Get the headers
    const headers = Object.keys(array[0]);
    const csvRows = [];

    // Create CSV header
    csvRows.push(headers.join(","));

    // Format each row
    for (const row of array) {
      csvRows.push(
        headers.map((header) => JSON.stringify(row[header], replacer)).join(",")
      );
    }

    return csvRows.join("\n");
  };

  const replacer = (key, value) => (value === null ? "" : value);
  const downloadCSV = (array, filename = `${"Responses"}.csv`) => {
    const csv = convertToCSV(array);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;

    // Append link to the body and trigger a click
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleDownload = () => {
    downloadCSV(data);
  };

  useEffect(() => {
    async function markMessagesAsRead(subDomainId) {
      try {
        // Update all records where siteId matches subDomainId and isRead is false
        const { data, error } = await supabase
          .from("userMessages")
          .update({ isRead: true })
          .eq("siteId", subDomainId)
          .eq("isRead", false);

        if (error) {
          throw error;
        }

        console.log("Messages marked as read successfully:", data);
        return data;
      } catch (error) {
        console.error("Error marking messages as read:", error);
        throw error; // Optionally rethrow error for further handling
      }
    }

    markMessagesAsRead(subDomain);
  }, []);

  return (
    <>
      <UpsertPlan />
      {!Loading ? (
        <div className="p-10 w-[100%]">
          <div className="text-[#000]">
            <h2 className="text-[36px] font-medium font-manrope leading-[60px] ">
              Keep track of all your customers in one place
            </h2>
          </div>

          <div className="mt-4 w-[100%] sm:w-[100%] h-full bg-white">
            <div className="flex flex-col">
              <div className="-m-1.5 overflow-x-auto">
                <div className="p-1.5 min-w-full inline-block align-middle">
                  <div className="border rounded-lg divide-y divide-gray-200">
                    <div className="py-3 px-4 flex flex-row items-center justify-between">
                      <div className="relative max-w-xs">
                        <label className="sr-only">Search</label>
                        <input
                          type="text"
                          name="hs-table-with-pagination-search"
                          id="hs-table-with-pagination-search"
                          className="py-2 px-3 ps-9 block w-full border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-yellow-500 focus:ring-yellow-500 disabled:opacity-50 disabled:pointer-events-none"
                          placeholder="Search for emails"
                          value={searchTerm}
                          onChange={handleSearch}
                        />
                        <div className="absolute inset-y-0 start-0 flex items-center pointer-events-none ps-3">
                          <svg
                            className="size-4 text-gray-400"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <circle cx="11" cy="11" r="8"></circle>
                            <path d="m21 21-4.3-4.3"></path>
                          </svg>
                        </div>
                      </div>
                      <div className="flex gap-4">
                        {downloadingCSV ? (
                          <div className="spinner"></div>
                        ) : (
                          <button
                            onClick={handleDownload}
                            className="bg-gray-100 text-zinc-700 px-4 py-2 rounded-md hover:bg-gray-200  flex flex-row items-center justify-center gap-2"
                          >
                            <MdOutlineFileDownload className="text-2xl text-zinc-500 " />
                            <p>CSV</p>
                          </button>
                        )}

                        {/* {
                                            deleting ? (<div className='spinner'></div>) : (
                                                <button
                                                onClick={()=>{}}
                                                    className="bg-gray-100 text-zinc-700 px-4 py-2 rounded-md hover:bg-zinc-400 hover:text-white"
                                                >
                                                    Delete
                                                </button>
                                            )
                                        } */}
                      </div>
                    </div>
                    <div className="overflow-hidden">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                          <tr>
                            <th scope="col" className="py-3 px-4 pe-0">
                              <div className="flex items-center h-5">
                                <input
                                  id="hs-table-pagination-checkbox-all"
                                  type="checkbox"
                                  className="border-gray-200 rounded text-yellow-500 focus:ring-yellow-500"
                                  checked={selectAll}
                                  onChange={handleSelectAll}
                                />
                                <label
                                  htmlFor="hs-table-pagination-checkbox-all"
                                  className="sr-only"
                                >
                                  Checkbox
                                </label>
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                            >
                              Email
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                            >
                              Phone
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                            >
                              Message
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                            >
                              Date
                            </th>
                            <th
                              scope="col"
                              className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase"
                            >
                              Time
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                          {currentEntries.map((item) => (
                            <tr key={item.id}>
                              <td className="py-3 ps-4">
                                <div className="flex items-center h-5">
                                  <input
                                    id={`hs-table-pagination-checkbox-${item.id}`}
                                    type="checkbox"
                                    className="border-gray-200 rounded text-yellow-500 focus:ring-yellow-500 "
                                    checked={selectedItems.includes(item.id)}
                                    onChange={() =>
                                      handleCheckboxChange(item.id)
                                    }
                                  />
                                  <label
                                    htmlFor={`hs-table-pagination-checkbox-${item.id}`}
                                    className="sr-only"
                                  >
                                    Checkbox
                                  </label>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-semibold text-gray-800">
                                {item?.email}
                              </td>
                              <td
                                className={`px-6 py-4 whitespace-nowrap text-sm  `}
                              >
                                {item?.firstName} {item?.lastName}
                              </td>
                              <td
                                className={`px-6 py-4 whitespace-nowrap text-sm  `}
                              >
                                {item?.phone}
                              </td>
                              <td
                                className={`px-6 py-4 whitespace-nowrap text-sm  `}
                              >
                                {item?.body}
                              </td>
                              <td
                                className={`px-6 py-4 whitespace-nowrap text-sm  `}
                              >
                                {item?.formattedDate}
                              </td>
                              <td
                                className={`px-6 py-4 whitespace-nowrap text-sm  `}
                              >
                                {item?.formattedTime}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="py-3 px-4">
                  <div className="flex items-center justify-between">
                    <div className="text-sm text-gray-500">
                      Page {currentPage} of {totalPages}
                    </div>
                    <div className="flex gap-2">
                      {Array.from({ length: totalPages }, (_, i) => (
                        <button
                          key={i + 1}
                          onClick={() => paginate(i + 1)}
                          className={`w-[30px] h-[30px] flex items-center justify-center text-sm font-medium rounded-full ${
                            currentPage === i + 1
                              ? "bg-yellow-500 text-white"
                              : "bg-gray-100 text-zinc-800"
                          }`}
                        >
                          {i + 1}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="overflow-x-auto mt-7">
      <div className="rounded-lg overflow-hidden bg-[#fbfbfb] pb-4">
        <table className="min-w-full divide-y divide-gray-200 ">
          <thead className="bg-[#F1F1F1]">
            <tr>
              <th className="p-4">
                <div className="flex items-center justify-center">
                  <input
                    type="checkbox"
                    className="rounded-[4px] border-gray-200 border p-1"
                  />
                </div>
              </th>
              <th className="px-4 py-4 text-left text-sm font-bold text-black ">
                Name
              </th>
              <th className="px-4 py-4 text-left text-sm font-bold text-black">
                Email
              </th>
              <th className="px-4 py-4 text-left text-sm font-bold text-black">
                New message
              </th>
              <th className="px-4 py-4 text-left text-sm font-bold text-black">
                Phone
              </th>
              <th className="px-4 py-4 text-left text-sm font-bold text-black">
                Source
              </th>
            </tr>
          </thead>
          <tbody className=" divide-y divide-gray-200 bg-[#fbfbfb]">
            {data && data.map((row, index) => (
              <tr key={index} className="px-2">
                <td className="px-4 py-2 border-y border-gray-200">
                  <div className="flex items-center justify-center">
                    <input
                      type="checkbox"
                      className="rounded-[4px] border-gray-200 border p-1"
                    />
                  </div>
                </td>
                <td className=" border-b border-gray-200 p-4 space-x-3">
                  <div className="flex items-center">
                    <div className="bg-gray-300 text-gray-800 rounded-full h-8 w-8 mr-4 flex items-center overflow-hidden justify-center">
                     <img src={`https://ui-avatars.com/api/?name=${row.firstName}+${row.lastName}?background=0D8ABC&color=fff`} className="" alt="" />
                    </div>
                    <span>{row.firstName}</span>
                  </div>
                </td>
                <td className="p-4  border-y border-gray-200">{row.email}</td>
                <td className="p-4  border-y border-gray-200">
                  {row.body}
                </td>
                <td className="p-4  border-y border-gray-200 text-blue-500">
                  {row.phone}
                </td>
                <td className="p-4 mb-2  border-y border-gray-200 text-blue-500">
                  website
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div> */}
        </div>
      ) : (
        <div className="flex w-full h-full items-center justify-center">
          <div className="spinner"></div>
        </div>
      )}
    </>
  );
};

export default CRMMain;
