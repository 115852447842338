export default function pageHtmlforSaving(subdomainId, mergedHtml, email) {
  return `<!DOCTYPE html>
            <html>
            <head>
            <meta charset="utf-8" />
            <script src="https://cdn.tailwindcss.com"></script>
            <meta name="siteId" content=${subdomainId}>

            <link rel="icon" href="https://www.helloworld.to/blackborderfavicon.svg" />
            <link rel="preconnect" href="https://fonts.googleapis.com">
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
            <link href="https://fonts.googleapis.com/css2?family=Manrope&display=swap" rel="stylesheet">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <!-- Group 1 -->
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Open+Sans:wght@400;700&family=Montserrat:wght@400;700&family=Lato:wght@400;700&family=Source+Sans+Pro:wght@400;700&display=swap">
            <!-- Group 2 -->
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&family=Poppins:wght@400;700&family=Raleway:wght@400;700&family=Nunito:wght@400;700&family=Merriweather:wght@400;700&display=swap">
            <!-- Group 3 -->
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&family=Playfair+Display:wght@400;700&family=Inconsolata:wght@400;700&family=Josefin+Sans:wght@400;700&family=PT+Sans:wght@400;700&display=swap">
            <!-- Group 4 -->
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;700&family=Dosis:wght@400;700&family=Rubik:wght@400;700&family=Oswald:wght@400;700&family=Bebas+Neue:wght@400;700&display=swap">
            <!-- Group 5 -->
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Quicksand:wght@400;700&family=Manrope:wght@400;700&family=Barlow:wght@400;700&family=Exo:wght@400;700&family=Arimo:wght@400;700&display=swap">
            <!-- Group 6 -->
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Heebo:wght@400;700&family=Mulish:wght@400;700&family=Asap:wght@400;700&family=Inter:wght@400;700&family=Signika:wght@400;700&display=swap">
            <!-- Group 7 -->
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Overpass:wght@400;700&family=Fjalla+One:wght@400;700&family=Space+Mono:wght@400;700&family=Karla:wght@400;700&family=Varela+Round:wght@400;700&display=swap">
            <!-- Group 8 -->
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Cabin:wght@400;700&family=Titillium+Web:wght@400;700&family=Arvo:wght@400;700&family=Alegreya:wght@400;700&family=Hind:wght@400;700&display=swap">
            <!-- Group 9 -->
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Catamaran:wght@400;700&family=Yanone+Kaffeesatz:wght@400;700&family=Crimson+Text:wght@400;700&family=Work+Sans:wght@400;700&family=Questrial:wght@400;700&display=swap">
            <!-- Group 10 -->
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Zilla+Slab:wght@400;700&family=Kanit:wght@400;700&family=Cardo:wght@400;700&family=Abel:wght@400;700&family=PT+Serif:wght@400;700&display=swap">


            <script src="https://cdn.jsdelivr.net/npm/@supabase/supabase-js@2.0.0/dist/umd/supabase.min.js"></script>
                        <style>
      /* Define font classes */
            .font-roboto { font-family: 'Roboto', sans-serif; }
        .font-open-sans { font-family: 'Open Sans', sans-serif; }
        .font-montserrat { font-family: 'Montserrat', sans-serif; }
        .font-lato { font-family: 'Lato', sans-serif; }
        .font-source-sans-pro { font-family: 'Source Sans Pro', sans-serif; }
        .font-roboto-condensed { font-family: 'Roboto Condensed', sans-serif; }
        .font-poppins { font-family: 'Poppins', sans-serif; }
        .font-raleway { font-family: 'Raleway', sans-serif; }
        .font-nunito { font-family: 'Nunito', sans-serif; }
        .font-merriweather { font-family: 'Merriweather', serif; }
        .font-ubuntu { font-family: 'Ubuntu', sans-serif; }
        .font-playfair-display { font-family: 'Playfair Display', serif; }
        .font-inconsolata { font-family: 'Inconsolata', monospace; }
        .font-josefin-sans { font-family: 'Josefin Sans', sans-serif; }
        .font-pt-sans { font-family: 'PT Sans', sans-serif; }
        .font-fira-sans { font-family: 'Fira Sans', sans-serif; }
        .font-dosis { font-family: 'Dosis', sans-serif; }
        .font-rubik { font-family: 'Rubik', sans-serif; }
        .font-oswald { font-family: 'Oswald', sans-serif; }
        .font-bebas-neue { font-family: 'Bebas Neue', sans-serif; }
        .font-quicksand { font-family: 'Quicksand', sans-serif; }
        .font-manrope { font-family: 'Manrope', sans-serif; }
        .font-barlow { font-family: 'Barlow', sans-serif; }
        .font-exo { font-family: 'Exo', sans-serif; }
        .font-arimo { font-family: 'Arimo', sans-serif; }
        .font-heebo { font-family: 'Heebo', sans-serif; }
        .font-mulish { font-family: 'Mulish', sans-serif; }
        .font-asap { font-family: 'Asap', sans-serif; }
        .font-inter { font-family: 'Inter', sans-serif; }
        .font-signika { font-family: 'Signika', sans-serif; }
        .font-overpass { font-family: 'Overpass', sans-serif; }
        .font-fjalla-one { font-family: 'Fjalla One', sans-serif; }
        .font-space-mono { font-family: 'Space Mono', monospace; }
        .font-karla { font-family: 'Karla', sans-serif; }
        .font-varela-round { font-family: 'Varela Round', sans-serif; }
        .font-cabin { font-family: 'Cabin', sans-serif; }
        .font-titillium-web { font-family: 'Titillium Web', sans-serif; }
        .font-arvo { font-family: 'Arvo', serif; }
        .font-alegreya { font-family: 'Alegreya', serif; }
        .font-hind { font-family: 'Hind', sans-serif; }
        .font-catamaran { font-family: 'Catamaran', sans-serif; }
        .font-yanone-kaffeesatz { font-family: 'Yanone Kaffeesatz', sans-serif; }
        .font-crimson-text { font-family: 'Crimson Text', serif; }
        .font-work-sans { font-family: 'Work Sans', sans-serif; }
        .font-questrial { font-family: 'Questrial', sans-serif; }
        .font-zilla-slab { font-family: 'Zilla Slab', serif; }
        .font-kanit { font-family: 'Kanit', sans-serif; }
        .font-cardo { font-family: 'Cardo', serif; }
        .font-abel { font-family: 'Abel', sans-serif; }
        .font-pt-serif { font-family: 'PT Serif', serif; }

  
      /* Additional styles for demonstration */
      .text-preview { font-size: 24px; margin-top: 20px; }
    </style>
            <script>
            document.addEventListener('DOMContentLoaded', function () {
                var sendButton = document.getElementsByName('send-button')[0]; // Get the first element with name="send-button"

                if (!sendButton) {
                    console.error('Error: send-button not found.');
                    return;
                } else{
                  console.log('button found.');
                 }


                sendButton.addEventListener('click', function () {
                    var contactSections = document.querySelectorAll('[name="contact-container"]');
                    console.log(contactSections)
                    console.log("clicked")
                    contactSections.forEach(function (section) {
                        var inputs = section.querySelectorAll('input, textarea'); // Include textarea elements
                        var formData = {};

                        // Collect data from inputs
                        inputs.forEach(function (input) {
                            formData[input.name] = input.value;
                        });

                        // Add siteId from meta tag if available
                        var metaSiteId = document.querySelector('meta[name="siteId"]');
                        if (metaSiteId) {
                            formData['siteId'] = metaSiteId.content;
                        }
                        
                        formData['ownerEmail'] = "${email}"

                        console.log(formData)
                        // Create and send the XHR request
                        var xhr = new XMLHttpRequest();
                        xhr.open('POST', 'https://helloworldai-production.up.railway.app/api/user/email/', true);
                        xhr.setRequestHeader('Content-Type', 'application/json');

                        xhr.onreadystatechange = function () {
                            if (xhr.readyState === XMLHttpRequest.DONE) {
                                if (xhr.status === 200) {
                                    console.log('The message was sent successfully');
                                    showCustomAlert('Message Sent!', 'success');
                                } else {
                                    // Log error details for better debugging
                                    console.error('Failed to send the message.');
                                    console.error('Status Code:', xhr.status);
                                    console.error('Response Text:', xhr.responseText);
                                    showCustomAlert('Failed to send message', 'error');
                                }
                            }else{
                            console.log("API")
                            }
                        };

                        xhr.onerror = function () {
                            // Handle network errors
                            console.error('Network error occurred while sending the message.');
                            showCustomAlert('Network error. Please try again later.', 'error');
                        };

                        xhr.send(JSON.stringify(formData));

                        console.log("API Done")
                    });
                });

                function showCustomAlert(message, type) {
                    var alertBox = document.createElement('div');
                    alertBox.classList.add('custom-alert', type);
                    alertBox.textContent = message;

                    document.body.appendChild(alertBox);

                    setTimeout(function () {
                        alertBox.style.opacity = '0';
                        setTimeout(function () {
                            alertBox.parentNode.removeChild(alertBox);
                        }, 2000);
                    }, 3000); // Adjust timing as needed
                }
            });
            </script>
            <script>
document.addEventListener('DOMContentLoaded', () => {
  console.log("Running");
  const elements2 = document.getElementsByName("background-image");
  console.log("Elements =>", elements2);

  for (let i = 0; i < elements2.length; i++) {
    let element = elements2[i];
    console.log("element in loop", element)
    if (element) {
      console.log("background found");
      element.removeAttribute('style');
    } else{
     console.log("element not found")}
  }
});



            
            </script>


            <script>
                function toggleAccordion(index) {
                    const items = document.querySelectorAll('.accordion-item');
                    const targetItem = items[index];
                    const content = targetItem.querySelector('[name="accordion-content"]');
                    const chevron = targetItem.querySelector('[name="chevron"]');
                    
                    // Hide all items
                    items.forEach((item, i) => {
                        if (i !== index) {
                            item.querySelector('[name="accordion-content"]').classList.add('hidden');
                            item.querySelector('[name="chevron"]').classList.remove('rotate-180');
                        }
                    });

                    // Toggle the selected item
                    content.classList.toggle('hidden');
                    chevron.classList.toggle('rotate-180');
                }
            </script>
            <script src="https://unpkg.com/flowbite@1.5.3/dist/flowbite.js"></script>

            <script>
                // Function to generate a 10-character unique ID
                function generateVisitorId() {
                return Math.random().toString(36).substr(2, 10);
                }

                // Function to detect the device type
                function getDeviceType() {
                const ua = navigator.userAgent;
                if (/tablet|ipad|playbook|silk|(android(?!.*mobi))/i.test(ua)) {
                    return 2; // Tablet
                }
                if (/Mobile|iP(hone|od)|Android.*Mobile|Windows Phone|webOS|BlackBerry/i.test(ua)) {
                    return 3; // Mobile
                }
                return 1; // Desktop
                }

                // Function to get the meta tag content by name
                function getMetaContent(name) {
                const meta = document.querySelector(\`meta[name="#33dollar{name}"]\`);
                return meta ? meta.content : '';
                }

                // Function to get the current path
                function getPath() {
                return window.location.pathname;
                }

                // Function to get the cached visitor ID
                function getCachedVisitorId() {
                return localStorage.getItem('visitor_id');
                }

                // Function to set the cached visitor ID
                function setCachedVisitorId(id) {
                localStorage.setItem('visitor_id', id);
                }

                // When the page loads, track the visitor
                window.onload = async function() {
                let visitorId = getCachedVisitorId();

                if (!visitorId) {
                    visitorId = generateVisitorId();
                    setCachedVisitorId(visitorId);
                }

                const userAgent = navigator.userAgent;
                const screen = getDeviceType();
                const siteId = getMetaContent('siteId');
                const path = getPath();

                try {
                    // Get the user's IP address and region
                    const response = await fetch('https://api.ipify.org?format=json');
                    const data = await response.json();
                    const ipAddress = data.ip;

                    const geoResponse = await fetch(\`https://ipapi.co/#33dollar{ipAddress}/json/\`);
                    const geoData = await geoResponse.json();
                    const region = geoData.continent_name;

                    // Send data to your server
                    const serverResponse = await fetch('https://helloworldai-production.up.railway.app/api/user/session/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        visitor_id: visitorId,
                        session_start: new Date().toISOString(),
                        userAgent: userAgent,
                        screen: screen,
                        siteId: siteId,
                        path: path,
                        region: region,
                        ip_address: ipAddress
                    })
                    });

                    if (!serverResponse.ok) {
                    throw new Error('Server response was not ok.');
                    }

                    console.log('Session data sent to server successfully');
                } catch (err) {
                    console.error('Error fetching IP, geolocation data, or sending data to server:', err);
                }
                }
            </script>
<script>
window.addEventListener('beforeunload', async (event) => {
  // Retrieve values from local storage and meta tag
  const visitorId = localStorage.getItem('visitor_id');
  const siteId = document.querySelector('meta[name="siteId"]').getAttribute('content');
  const path = window.location.pathname;

  // Log values for debugging (optional)
  console.log("Visitor ID:", visitorId);
  console.log("Site ID:", siteId);
  console.log("Path:", path);

  if (visitorId) {
    // Prepare the body for the API request
    const body = JSON.stringify({
      session_end: new Date().toISOString(),
      path: path,
      siteId: siteId
    });

    try {
      // Send the session end data to the server
      await fetch('https://helloworldai-production.up.railway.app/api/user/session/end', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: body
      });
    } catch (error) {
      console.error('Failed to send session end data:', error);
    }
  }

  // Required to prompt the user with a confirmation dialog before leaving the page
  event.returnValue = '';
});
</script>





          <script>
   document.addEventListener('DOMContentLoaded', function() {
    const openButton = document.querySelector('[name="menu-open-button"]');
    const closeButton = document.querySelector('[name="menu-close-button"]');
    const closeButton2 = document.querySelector('[name="menu-close-button-link"]');
    const mobileMenu = document.querySelector('[name="mobile-menu"]');

    if (openButton) {
        openButton.addEventListener('click', function() {
            mobileMenu.style.display = 'flex';
        });
    }

    if (closeButton) {
        closeButton.addEventListener('click', function() {
            mobileMenu.removeAttribute('style');
            console.log("Removed")
        });
    }

     if (closeButton2) {
        closeButton2.addEventListener('click', function() {
            mobileMenu.removeAttribute('style');
            console.log("Removed")
        });
    }
});

</script>
<script>
 document.addEventListener("DOMContentLoaded", function () {
    var buttonNames = ["background-image-editor-button", "background-image-editor-close-button"];

    buttonNames.forEach(function (name) {
        var elements = document.getElementsByName(name);

        if (elements.length === 0) {
            console.warn("No elements found with name: " + name);
            return;
        }

        console.log("Found elements with name: " + name, elements);

        elements.forEach(function (element) {
            if (element) {
                console.log("Removing element:", element);
                element.remove(); // Completely removes the element from the DOM
            }
        });
    });
});


</script>



 <style>
      /* Define font classes */
     .font-roboto { font-family: 'Roboto', sans-serif; }
.font-open-sans { font-family: 'Open Sans', sans-serif; }
.font-montserrat { font-family: 'Montserrat', sans-serif; }
.font-lato { font-family: 'Lato', sans-serif; }
.font-source-sans-pro { font-family: 'Source Sans Pro', sans-serif; }
.font-roboto-condensed { font-family: 'Roboto Condensed', sans-serif; }
.font-poppins { font-family: 'Poppins', sans-serif; }
.font-raleway { font-family: 'Raleway', sans-serif; }
.font-nunito { font-family: 'Nunito', sans-serif; }
.font-merriweather { font-family: 'Merriweather', serif; }
.font-ubuntu { font-family: 'Ubuntu', sans-serif; }
.font-playfair-display { font-family: 'Playfair Display', serif; }
.font-inconsolata { font-family: 'Inconsolata', monospace; }
.font-josefin-sans { font-family: 'Josefin Sans', sans-serif; }
.font-pt-sans { font-family: 'PT Sans', sans-serif; }
.font-fira-sans { font-family: 'Fira Sans', sans-serif; }
.font-dosis { font-family: 'Dosis', sans-serif; }
.font-rubik { font-family: 'Rubik', sans-serif; }
.font-oswald { font-family: 'Oswald', sans-serif; }
.font-bebas-neue { font-family: 'Bebas Neue', sans-serif; }
.font-quicksand { font-family: 'Quicksand', sans-serif; }
.font-manrope { font-family: 'Manrope', sans-serif; }
.font-barlow { font-family: 'Barlow', sans-serif; }
.font-exo { font-family: 'Exo', sans-serif; }
.font-arimo { font-family: 'Arimo', sans-serif; }
.font-heebo { font-family: 'Heebo', sans-serif; }
.font-mulish { font-family: 'Mulish', sans-serif; }
.font-asap { font-family: 'Asap', sans-serif; }
.font-inter { font-family: 'Inter', sans-serif; }
.font-signika { font-family: 'Signika', sans-serif; }
.font-overpass { font-family: 'Overpass', sans-serif; }
.font-fjalla-one { font-family: 'Fjalla One', sans-serif; }
.font-space-mono { font-family: 'Space Mono', monospace; }
.font-karla { font-family: 'Karla', sans-serif; }
.font-varela-round { font-family: 'Varela Round', sans-serif; }
.font-cabin { font-family: 'Cabin', sans-serif; }
.font-titillium-web { font-family: 'Titillium Web', sans-serif; }
.font-arvo { font-family: 'Arvo', serif; }
.font-alegreya { font-family: 'Alegreya', serif; }
.font-hind { font-family: 'Hind', sans-serif; }
.font-catamaran { font-family: 'Catamaran', sans-serif; }
.font-yanone-kaffeesatz { font-family: 'Yanone Kaffeesatz', sans-serif; }
.font-crimson-text { font-family: 'Crimson Text', serif; }
.font-work-sans { font-family: 'Work Sans', sans-serif; }
.font-questrial { font-family: 'Questrial', sans-serif; }
.font-zilla-slab { font-family: 'Zilla Slab', serif; }
.font-kanit { font-family: 'Kanit', sans-serif; }
.font-cardo { font-family: 'Cardo', serif; }
.font-abel { font-family: 'Abel', sans-serif; }
.font-pt-serif { font-family: 'PT Serif', serif; }

       

.manrope{
  font-family: "Manrope", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}


.body{
font-family: "Manrope", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
            }


  
           
            .custom-alert {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    z-index: 9999;
    opacity: 1;
    transition: opacity 0.3s ease-out;
}

.custom-alert.success {
    background-color: #4CAF50; /* Green */
}



.custom-alert.error {
    background-color: #f44336; /* Red */
}
</style>




            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
            <link href="https://tdrdiflbdlftfwudjeim.supabase.co/storage/v1/object/public/hello-world/public/reg_fonts.css" rel="stylesheet">
            </head>
            <body class="custom-scrollbar">
            ${mergedHtml}
            </body>
            </html >`;
}
